import React from "react";
import ReactPlayer from 'react-player'
import {
  VideoWrapper,
  VideoDataContainer,
  VideoDataContainerTitle,
  VideoDataContainerDescription,
} from "./styles";

const Banner = () => {
  return (
    <VideoWrapper>
      <VideoDataContainer>
        <VideoDataContainerTitle>
          Crafting Tomorrow’s Innovation, Today!
        </VideoDataContainerTitle>
        <VideoDataContainerDescription>
          Every line of code and design is a testament to our dedication to
          innovation. Join us in crafting the solutions that shape the future,
          where innovation is not just a goal but a craft at Techabes. conquer
          complex challenges through innovation and agility.
        </VideoDataContainerDescription>
        <a href="/contact-us" className="consult-us-button">
          Let’s Build Your Idea
        </a>
      </VideoDataContainer>
      <ReactPlayer url='https://d29z5i6uc22g11.cloudfront.net/banner.mp4' loop={true} muted={true} playing={true} playsinline={true} controls={false} className="intro_video" />
      {/* <video autoPlay loop muted className="intro_video">
        <source src="https://d29z5i6uc22g11.cloudfront.net/banner.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video> */}
    </VideoWrapper>
  );
};

export default Banner;
