import styled from "styled-components";
import { SectionWrapper } from '../common-styles';

export const ContactUsSectionWrapper = styled(SectionWrapper)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  @media only screen and (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
`;

export const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  width: 70%;
  justify-content: center;
  @media only screen and (max-width: 500px) {
    width: 100%;
  }
`;

export const DescriptionTitle = styled.div`
  margin-bottom: 20px;
  font-size: 32px;
  font-weight: 700;
  color: #fc6600;
  @media only screen and (max-width: 1280px) {
    font-size: 28px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 22px;
  }
`;

export const DescriptionLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 20px;
  line-height: 24px;
  color: #fff;
  line-height: 1.4;
  margin-bottom: 10px;
  @media only screen and (max-width: 768px) {
    font-size: 16px;
  }
`;

export const RightSection = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: #fff;
  padding: 30px;
  .MuiAlert-message {
    font-size: 16px;
  }
  .MuiAlert-action {
    align-items: center;
  }
`;

export const RightSectionTitle = styled.div`
  color: #000;
  display: flex;
  /* justify-content: center; */
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 40px;
  @media only screen and (max-width: 1280px) {
    font-size: 20px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 18px;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FieldContainer = styled.div`
  display: flex;
  gap: 20px;
  @media only screen and (max-width: 500px) {
    gap: 0;
    flex-direction: column;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 20px;
  .error{
    color: red;
    font-size: 12px;
  }
  label {
    margin-bottom: 5px;
    font-size: 20px;
    @media only screen and (max-width: 1440px) {
      font-size: 16px;
    }
    color: #000;
  }
  input {
    height: 30px;
    border-radius: 4px;
    border: 1px solid black;
    padding: 2px 4px;
    &:focus {
      outline: none;
    }
  }
  textarea {
    border-radius: 4px;
    border: 1px solid black;
    padding: 2px 4px;
    &:focus {
      outline: none;
    }
  }
  .icon-added {
    width: 100%;
    border-radius: 0;
    border: 0;
    padding: 0;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
  border-radius: 4px;
  border: 1px solid black;
  padding: 2px 4px;
  gap: 4px;
  color: black;
`;

export const SubmitButtonWrapper = styled.div`
  display: flex;
`;

export const SubmitButton = styled.button`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  background-color: #fc6600;
  color: #fff;
  border: 0;
  border-radius: 8px;
  cursor: pointer;
  width: fit-content;
`;
