import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import StarIcon from '@mui/icons-material/Star';
import { TitleWrapper, Title, Description, InfoWrapper } from '../common-styles';
import {
  CaseStudiesSectionWrapper,
  TestimonialItemWrapper,
  TestimonialItem,
  StarContainer,
  TestimonialMessage,
  TestimonialWriter,
  Client,
  ClientName,
  ClientPosition,
} from "./style";
import { List } from "./constants";

const ClientTestimonials = () => {
  const settings = {
    dots: true,
    slidesToShow: 3,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          dots: true,
          slidesToShow: 2,
          autoplay: true,
        },
      },
      {
        breakpoint: 650,
        settings: {
          dots: true,
          slidesToShow: 1,
          autoplay: true,
        },
      },
    ],
  };
  return (
    <CaseStudiesSectionWrapper>
      <TitleWrapper>
        <InfoWrapper>
          <Title>Meet Our Clients: Testimonials</Title>
          <Description>"They Dared To Dream, We Dared To Build."</Description>
        </InfoWrapper>
      </TitleWrapper>
      <Slider {...settings}>
        {List.map((item, index) => (
          <TestimonialItemWrapper key={index}>
            <TestimonialItem>
              <div style={{ display: "flex", flexDirection: "column", gap: "32px" }}>
                <StarContainer>
                  <StarIcon />
                  <StarIcon />
                  <StarIcon />
                  <StarIcon />
                  <StarIcon />
                </StarContainer>
                <TestimonialMessage>{item.message}</TestimonialMessage>
              </div>
              <TestimonialWriter>
                {item.clientImg ? (
                  <img
                    src={item.clientImg}
                    alt=""
                    width={"56px"}
                    height={"56px"}
                  />
                ): null}
                <Client>
                  <ClientName>{item.clientName}</ClientName>
                  <ClientPosition>{item.clientCompany}</ClientPosition>
                </Client>
              </TestimonialWriter>
            </TestimonialItem>
          </TestimonialItemWrapper>
        ))}
      </Slider>
    </CaseStudiesSectionWrapper>
  );
};

export default ClientTestimonials;
