export const TECHNOLOGIES_DETAILS = {
  mobile: {
    titlePage: {
      title: "Mobile Technology Solutions",
      subTitle: "Powering Your Business with Cutting-Edge Mobile Experiences",
      text: "At Techabes, we specialize in developing high-performance mobile applications that deliver seamless experiences across all platforms. Whether you need native apps, hybrid solutions, or IoT and AR/VR integration, we’ve got you covered with the latest mobile technologies to transform your business.",
      image: "https://d29z5i6uc22g11.cloudfront.net/mobile-tech-1.svg",
    },
    servicesProvided: [
      {
        title: "Native App Development",
        text: "Crafting platform-specific apps for iOS and Android using Swift, Kotlin, and Java, designed to leverage the full potential of mobile hardware and software.",
      },
      {
        title: "Hybrid App Development",
        text: "Developing cross-platform apps with frameworks like React Native and Flutter, ensuring faster delivery and consistent performance across devices.",
      },
      {
        title: "IoT and AR/VR Integration",
        text: "Creating immersive and connected mobile experiences through IoT, ARKit, ARCore, and VR technologies to enhance customer engagement and business functionality.",
      },
    ],
    applicationsInfoImage: "https://d29z5i6uc22g11.cloudfront.net/mobile-tech-2.svg",
    applicationsInfo: [
      {
        title: "Scalable Solutions",
        subTitle:
          "Mobile apps that grow with your business, handling increased user traffic and functionality with ease.",
      },
      {
        title: "User-Centric Designs",
        subTitle:
          "Intuitive and engaging UI/UX designs that ensure a seamless user experience across platforms.",
      },
      {
        title: "Offline Capabilities",
        subTitle:
          "Deliver essential features with offline functionality, ensuring access even without internet connectivity.",
      },
      {
        title: "Faster Time to Market",
        subTitle:
          "With hybrid development and agile methodologies, we launch your mobile app faster without compromising on quality.",
      },
    ],
    whyUs: {
      title: "Reasons to Choose Us as Your Mobile Tech Partner",
      info: [
        {
          title: "Expertise in Multiple Platforms",
          text: "From iOS and Android to hybrid apps, our team has in-depth knowledge of all leading mobile technologies.",
        },
        {
          title: "Agile Development Approach",
          text: "We deliver apps quickly and efficiently using agile methodologies that prioritize iterative development and feedback.",
        },
        {
          title: "Focus on User Experience",
          text: "Our designs are centered around your users, ensuring that every interaction is intuitive and delightful.",
        },
        {
          title:  "End-to-End Support",
          text: "We provide continuous support throughout the app lifecycle, from development to maintenance and updates."
        },
        {
          title: "Cutting-Edge Tools & Technologies",
          text: "We stay ahead of mobile trends and implement the latest tech like IoT and AR/VR to enhance your app's functionality."
        },
        {
          title: "Scalability and Security",
          text: "Our mobile solutions are built to scale with your business while ensuring data protection and security compliance.",
        }
      ],
    },
  },
  web: {
    titlePage: {
      title: "Web Development Technology",
      subTitle: "Building Robust and Scalable Web Solutions for the Digital Age",
      text: "At Techabes, we create fast, secure, and scalable web applications tailored to your business needs. From front-end user experiences to back-end server infrastructure, we use the latest technologies to ensure your web platform is designed to perform and grow with your business",
      image: "https://d29z5i6uc22g11.cloudfront.net/web-tech-1.svg",
    },
    servicesProvided: [
      {
        title: "Frontend Development",
        text: "Using modern frameworks like React, Angular, and Vue.js to build interactive, responsive, and visually stunning web interfaces.",
      },
      {
        title: "Backend Development",
        text: "Secure and scalable server-side applications built using Node.js, Python, and PHP that handle complex business logic and data management.",
      },
      {
        title: "Full-Stack Integration",
        text: "Offering both front-end and back-end development with seamless integration to ensure your website performs flawlessly on all levels.",
      },
    ],
    applicationsInfoImage: "https://d29z5i6uc22g11.cloudfront.net/web-tech-2.svg",
    applicationsInfo: [
      {
        title: "Responsive Design",
        subTitle:
          "Ensuring your website performs perfectly across all devices, from desktops to mobile.",
      },
      {
        title: "SEO-Friendly",
        subTitle:
          "Optimized for search engines, increasing visibility and helping drive organic traffic to your site.",
      },
      {
        title: "High Performance",
        subTitle:
          "We implement performance optimization techniques to ensure fast load times, even during heavy traffic periods.",
      },
      {
        title: "Advanced Security",
        subTitle:
          "Keeping your web platform safe from cyber threats with the latest in web security technologies.",
      },
    ],
    whyUs: {
      title: "Reasons to Choose Us as Your Web Tech Partner",
      info: [
        {
          title: "Tailored Web Development",
          text: "We create customized solutions designed to meet your specific business objectives.",
        },
        {
          title: "Expertise in Modern Web Technologies",
          text: "We leverage the latest tools and frameworks like React, Node.js, and Angular to create high-performance web applications.",
        },
        {
          title: "Performance Optimization",
          text: "Our focus on speed and efficiency ensures your web app runs smoothly, enhancing user satisfaction and engagement.",
        },
        {
          title:  "Security Compliance",
          text: "We implement the latest security practices, from SSL to advanced encryption, to keep your data safe."
        },
        {
          title: "Scalable Architecture",
          text: "Our web solutions are designed to grow with your business, easily adapting to increased traffic and new features."
        },
        {
          title: "Long-Term Support and Maintenance",
          text: "Beyond development, we offer ongoing support and updates to ensure your web app stays relevant and functional.",
        }
      ],
    },
  },
  cloud: {
    titlePage: {
      title: "Cloud & DevOps Solutions",
      subTitle: "Future-Proof Your Business with Scalable and Secure Cloud Solutions",
      text: "Techabes offers cloud-based solutions that enable businesses to scale, innovate, and optimize their operations. From cloud migration to DevOps practices, we ensure that your business is always ready to meet the demands of a modern, digital world.",
      image: "https://d29z5i6uc22g11.cloudfront.net/cloud-tech-1.svg",
    },
    servicesProvided: [
      {
        title: "Cloud Migration Services",
        text: "Seamlessly move your infrastructure to the cloud with minimal disruption, ensuring improved scalability and reduced costs.",
      },
      {
        title: "DevOps Integration",
        text: "Automate and streamline development processes with DevOps practices, enhancing collaboration and accelerating delivery times.",
      },
      {
        title: "Cloud-Native Development",
        text: "Build cloud-first applications using AWS, Azure, and Google Cloud that are optimized for performance, security, and scalability.",
      },
    ],
    applicationsInfoImage: "https://d29z5i6uc22g11.cloudfront.net/cloud-tech-2.svg",
    applicationsInfo: [
      {
        title: "Flexible Scalability",
        subTitle:
          "Easily scale your infrastructure up or down to meet changing demands, avoiding over-provisioning or underperformance.",
      },
      {
        title: "Cost Efficiency",
        subTitle:
          "Cloud solutions offer pay-as-you-go models that reduce upfront costs and optimize resource usage.",
      },
      {
        title: "Enhanced Security",
        subTitle:
          "Advanced cloud security measures, including encryption, access control, and compliance with regulatory standards, safeguard your data.",
      },
      {
        title: "Faster Time to Market",
        subTitle:
          "Cloud-native and DevOps practices allow for rapid deployment and continuous delivery, enabling you to launch features faster.",
      },
    ],
    whyUs: {
      title: "Reasons to Choose Us as Your Cloud Tech Partner",
      info: [
        {
          title: "Expertise in Major Cloud Platforms",
          text: "We work with industry-leading platforms like AWS, Microsoft Azure, and Google Cloud to deliver the best cloud solutions for your business.",
        },
        {
          title: "DevOps-Driven Development",
          text: "Our integrated DevOps practices ensure continuous integration and delivery, making your business more agile and responsive to market needs.",
        },
        {
          title: "Cloud-Native Approach",
          text: "We design applications that leverage the full potential of the cloud, ensuring they are scalable, resilient, and secure.",
        },
        {
          title:  "Cost-Effective Cloud Strategies",
          text: "We help optimize cloud infrastructure to reduce costs and improve operational efficiency, offering solutions that are both effective and economical."
        },
        {
          title: "Enhanced Security Protocols",
          text: "We implement top-tier security measures in all cloud solutions, ensuring your data remains safe and compliant with industry regulations."
        },
        {
          title: "Future-Proof Solutions",
          text: "We build cloud solutions that are adaptable to future technologies and market trends, ensuring your business stays ahead of the curve.",
        }
      ],
    },
  },
};
