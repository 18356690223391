import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import HeaderContentWeb from "./header-web";
import HeaderMobileContent from "./header-mobile";
import { HeaderWrapper, HeaderContainer, LogoWrapper } from "./style";

const Header = ({ backgroundColor }) => {
  let navigate = useNavigate();
  const [isScroll, setIsScroll] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const listenScrollEvent = () => {
    const scroll = window.scrollY;
    if (scroll > 50) {
      setIsScroll(true);
    } else {
      setIsScroll(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    return () => {
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, []);

  const handleNavIconClick = () => {
    navigate("/");
  };

  const handleUpdateHover = (value) => {
    setIsHovered(value);
  };

  return (
    <HeaderWrapper backgroundColor={backgroundColor} isScroll={isScroll} isHovered={isHovered}>
      <HeaderContainer>
        <LogoWrapper onClick={handleNavIconClick}>
          <img src="https://d29z5i6uc22g11.cloudfront.net/Logologo.svg" alt="" />
        </LogoWrapper>
        <HeaderContentWeb handleUpdateHover={handleUpdateHover} />
      </HeaderContainer>
      <HeaderMobileContent />
    </HeaderWrapper>
  );
};

export default Header;
