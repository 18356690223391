import styled from "styled-components";

export const HeaderMobileContainer = styled.div`
  display: none;
  @media only screen and (max-width: 1024px) {
    display: flex;
    position: absolute;
    top: 20px;
    right: 15px;
    gap: 10px;
  }

  label {
    display: flex;
    flex-direction: column;
    width: 30px;
    cursor: pointer;
    justify-content: center;
  }

  label span {
    background: #fff;
    border-radius: 10px;
    height: 2px;
    margin: 2px 0;
    transition: 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6);
  }

  span:nth-of-type(1) {
    width: 50%;
  }

  span:nth-of-type(2) {
    width: 100%;
  }

  span:nth-of-type(3) {
    width: 75%;
  }

  input[type="checkbox"] {
    display: none;
  }

  input[type="checkbox"]:checked ~ span:nth-of-type(1) {
    transform-origin: bottom;
    transform: rotatez(45deg) translate(2px, -2px);
  }

  input[type="checkbox"]:checked ~ span:nth-of-type(2) {
    transform-origin: top;
    transform: rotatez(-45deg);
  }

  input[type="checkbox"]:checked ~ span:nth-of-type(3) {
    transform-origin: bottom;
    width: 50%;
    transform: translate(12px, -3px) rotatez(45deg);
  }

  .contact-us-button {
        text-decoration: none;
        list-style-type: none;
        color: #fff;
        border: 1px solid #fc6600;
        background-color: #fc6600;
        font-weight: 600;
        padding: 8px 16px;
        border-radius: 30px;
        margin: 15px 0;
        transition: 1s;
        font-size: 12px;
        &:hover {
          color: #fc6600;
          box-shadow: inset 300px 0 0 0 #fff;
        }
      }
`;

export const HeaderList = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: #fff;
  width: 100vw;
  position: absolute;
  right: -14px;
  top: 40px;
`;

export const HeaderItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid grey;
  padding: 10px 30px;
  @media only screen and (max-width: 500px) {
    padding: 10px 15px;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

export const HeaderItem = styled.div`
  cursor: pointer;
  font-size: 16px;
  color: #000;
  font-weight: 600;
  &:hover {
      color: #fc6600;
    }
`;

export const HeaderitemListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 10px;
  .title {
    font-size: 12px;
    font-weight: 500;
    color: #000;
    margin: 0;
    &:hover {
      color: #fc6600;
    }
  }
`;

export const HeaderitemListTitle = styled.a`
  cursor: pointer;
  text-decoration: none;
  border: 0;
  outline: 0;
  font-size: 16px;
  margin: 2px 0;
  color: #000;
`;
