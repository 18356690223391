import styled from "styled-components";

export const BrandSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 0;
  max-width: 1700px;
  margin: 0 auto;
  overflow: hidden;
  .rfm-marquee-content {
    display: flex;
    white-space: nowrap; /* Prevents content from wrapping */
    animation: marquee 20s linear 0s infinite;
    animation-play-state: running;
    animation-delay: 0s;
    @keyframes marquee {
      from {
        transform: translateX(0%); /* Start position */
      }
      to {
        transform: translateX(-100%); /* End position */
      }
    }
  }

  @media only screen and (max-width: 1900px) {
    max-width: 1440px;
  }
  @media only screen and (max-width: 1550px) {
    max-width: 1340px;
  }
  @media only screen and (max-width: 1440px) {
    max-width: 1180px;
  }
  @media only screen and (max-width: 1280px) {
    padding: 20px 30px;
    max-width: calc(100% - 20px);
  }
`;

export const ImageContainer = styled.div`
  width: 310px;
  border-right: 1px solid #fff;
  padding: 0 40px;
  margin: 0 auto;
  box-sizing: border-box;
`;

export const BrandSectionItem = styled.img`
  height: 128px;
  max-width: 350px;
  display: flex;
  align-items: center;
  margin: 0 auto;
  @media only screen and (max-width: 1280px) {
    max-width: 200px;
    height: 100px;
  }
  @media only screen and (max-width: 500px) {
    max-width: 100px;
    height: 60px;
    padding: 0 20px;
  }
`;

export const DescriptionTitle = styled.div`
  margin-bottom: 40px;
  font-size: 24px;
  font-weight: 600;
  color: #fc6600;
  @media only screen and (max-width: 1280px) {
    font-size: 24px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 20px;
  }
`;

export const DescriptionLabel = styled.div`
  font-size: 20px;
  line-height: 24px;
  color: #fff;
  margin-bottom: 25px;
  @media only screen and (max-width: 768px) {
    font-size: 16px;
  }
`;
