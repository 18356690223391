export const CASE_STUDY_DATA = [
  {
    link: "/portfolio/on-demand-service-app",
    img: "/go_cabs_list.png",
  },
  {
    link: "/portfolio/beauty-app",
    img: "/beauty_app_list.png",
  },
  {
    link: "/portfolio/crm-app",
    img: "/crm_app_list.png",
  },
  {
    link: "/portfolio/cryptoloop-app",
    img: "/cryptoloop_list.png",
  },
  {
    link: "/portfolio/glamify-me-app",
    img: "/glamify_me_list.png",
  },
  {
    link: "/portfolio/social-media-app",
    img: "/social_media_list.png",
  },
];
