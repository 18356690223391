import styled from "styled-components";
import { SectionWrapper } from '../common-styles';

export const CaseStudiesSectionWrapper = styled(SectionWrapper)`
  position: relative;
  color: #fff;
  @media only screen and (max-width: 1024px) {
    padding: 30px;
  }
  .slick-dots button:before {
    color: #fff;
  }
  .slick-dots li.slick-active button:before {
    color: #fff;
    opacity: 1;
  }
`;

export const TestimonialItemWrapper = styled.div`
  box-sizing: border-box;
  height: 510px;
  gap: 20px;
  @media only screen and (max-width: 1900px) {
    height: 470px;
  }
  @media only screen and (max-width: 1550px) {
    height: 475px;
  }
  @media only screen and (max-width: 1440px) {
    height: 530px;
  }
  @media only screen and (max-width: 1280px) {
    height: 320px;
  }
  @media only screen and (max-width: 1120px) {
    height: 330px;
  }
  @media only screen and (max-width: 1024px) {
    height: 350px;
  }
  @media only screen and (max-width: 768px) {
    height: 420px;
  }
  @media only screen and (max-width: 650px) {
    height: 100%;
  }
  /* @media only screen and (max-width: 850px) {
    height: 390px;
  } */
`;

export const TestimonialItem = styled.div`
  margin: 10px;
  padding: 32px;
  border: 1px solid #fff;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: calc(100% - 20px);
  justify-content: space-between;
  gap: 20px;
`;

export const StarContainer = styled.div`
  display: flex;
  gap: 4px;
  color: #fc6600;
`;

export const TestimonialMessage = styled.div`
  color: #fff;
  font-size: 22px;
  line-height: 1.3;
  margin: 0;
  @media only screen and (max-width: 1900px) {
    font-size: 18px;
  }
  @media only screen and (max-width: 1280px) {
    font-size: 14px;
  }
`;

export const TestimonialWriter = styled.div`
  display: flex;
  gap: 20px;
  font-size: 20px;
  @media only screen and (max-width: 1024px) {
    font-size: 16px;
  }
  img {
    border-radius: 50%;
  }
`;

export const Client = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ClientName = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 1.5;
  @media only screen and (max-width: 1900px) {
    font-size: 16px;
  }
`;

export const ClientPosition = styled(ClientName)`
  font-weight: 400;
  @media only screen and (max-width: 1280px) {
    font-size: 12px;
  }
`;