import styled from "styled-components";
import { SectionWrapper } from "../../common-style";

export const NumberSectionWrapper = styled(SectionWrapper)`
  position: relative;
  height: 125px;
  padding: 0;
  @media only screen and (max-width: 1440px) {
    height: 75px;
  }
  @media only screen and (max-width: 768px) {
    height: fit-content;
    align-items: center;
    margin-bottom: 30px;
  }
`;

export const Container = styled.div`
  background: #fc6600;
  padding: 70px 0;
  width: calc(100%  - 100px);
  border-radius: 10px;
  position: absolute;
  margin-left: 50px;
  @media only screen and (max-width: 1440px) {
    padding: 30px 0;
  }
  @media only screen and (max-width: 1024px) {
    margin-left: 30px;
    width: calc(100%  - 60px);
  }
  @media only screen and (max-width: 768px) {
    position: relative;
    margin-left: 0;
  }
  @media only screen and (max-width: 500px) {
    width: 270px;
    padding: 0;
  }
`;

export const ItemWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  @media only screen and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const NumberWrapper = styled.div`
  position: relative;
  text-align: center;
  &:nth-child(2):before {
    position: absolute;
    content: "";
    background: #fff;
    width: 1px;
    height: 100%;
    top: 0;
    left: 25px;
  }
  &:nth-child(2):after {
    position: absolute;
    content: "";
    background: #fff;
    width: 1px;
    height: 100%;
    top: 0;
    right: 25px;
  }
  @media screen and (max-width: 768px) {
    width: 80%;
    text-align: center;
    padding: 20px 0;
    margin: 0 auto;
    &:nth-child(2):before {
      width: 100%;
      height: 1px;
      top: 0;
      left: 0;
      position: absolute;
      content: "";
      background: #fff;
    }
    &:nth-child(2):after {
      width: 100%;
      height: 1px;
      position: absolute;
      content: "";
      background: #fff;
      right: 0;
      bottom: 0;
      top: auto;
    }
  }
`;

export const NumberTitle = styled.span`
  color: #fff;
  font-size: 60px;
  margin: 0 auto;
  padding: 0;
  border: 0;
  outline: 0;
  @media only screen and (max-width: 1440px) {
    font-size: 40px;
  }
`;

export const DataDetail = styled.div`
  font-size: 16px;
  color: #fff;
  display: block;
  /* padding-top: 15px; */
  line-height: 1.3;
  @media only screen and (max-width: 1440px) {
    font-size: 14px;
  }
`;
