import React from "react";
import {
  CoreValuesSectionWrapper,
  CoreValuesContainer,
  Title,
  DetailsContainer,
  DetailsItem,
  Number,
  Divider,
  Content,
  ItemTitle,
  Info,
} from "./style";

const core_values = [
  {
    number: "01",
    title: "Innovation First",
    description:
      "We believe that the future is shaped by ideas that challenge the status quo. That’s why we’re always looking for creative ways to solve modern business problems through innovative technology.",
  },
  {
    number: "02",
    title: "Client-Centric Approach",
    description:
      "Our clients are at the heart of everything we do. We listen, adapt, and deliver solutions that exceed expectations, ensuring every project feels personal and collaborative.",
  },
  {
    number: "03",
    title: "Integrity in Every Step",
    description:
      "At Techabes, we value transparency and trust. We believe in building lasting relationships with our clients through honesty, accountability, and delivering on our promise.",
  },
];

const CoreValue = () => {
  return (
    <div style={{ backgroundColor: "#fff" }}>
      <CoreValuesSectionWrapper>
        <CoreValuesContainer>
          <Title>Our Core Values</Title>
          <DetailsContainer>
            {core_values.map((item) => (
              <DetailsItem>
                <Content>
                  <Number>
                    {item.number}
                    <Divider />
                  </Number>
                  <ItemTitle>{item.title}</ItemTitle>     
                </Content>
                <Info>{item.description}</Info>
              </DetailsItem>
            ))}
          </DetailsContainer>
        </CoreValuesContainer>
      </CoreValuesSectionWrapper>
    </div>
  );
};

export default CoreValue;