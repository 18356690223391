import React, { useState, useRef } from "react";
import { useDraggable } from "react-use-draggable-scroll";
import { useParams } from "react-router";
import { TECHNOLOGIES_INFO } from "../../constants";
import {
  TechnologiesWrapper,
  TitleContainer,
  Heading,
  SubHeading,
  ChipWrapper,
  ChipItem,
  TabChip,
  TechStackWrapper,
  ServiceTabDetail,
  TechStackDetail,
  GridPanel,
  TechIcon,
} from "./style";

const TechnologiesSection = () => {
  const { id } = useParams();
  const data = TECHNOLOGIES_INFO[id] ?? null;
  const [selectedPill, setSelectedPill] = useState(0);
  const ref = useRef();
  const { events } = useDraggable(ref);
  return (
    <div style={{ backgroundColor: "#fff" }}>
      <TechnologiesWrapper>
        <TitleContainer>
          <Heading dangerouslySetInnerHTML={{ __html: data.title }} />
          <SubHeading dangerouslySetInnerHTML={{ __html: data.subTitle }} />
        </TitleContainer>
        <ChipWrapper {...events} ref={ref}>
          {data.list.map((item, index) => (
            <ChipItem key={index} onClick={() => setSelectedPill(index)}>
              <TabChip
                className={index === selectedPill ? "isActive" : ""}
                isActive={index === selectedPill}
              >
                <span>{item.title}</span>
              </TabChip>
            </ChipItem>
          ))}
        </ChipWrapper>
        <TechStackWrapper>
          <ServiceTabDetail>
            {data.list.map((item, index) => (
              <TechStackDetail
                key={index}
                style={{
                  display: index === selectedPill ? "block" : "none",
                }}
              >
                <GridPanel>
                  {item.items.map((tech, index1) => (
                    <TechIcon key={index1}>
                      <figure>
                        <img src={tech.link} alt={""} />
                      </figure>
                      <p>{tech.title}</p>
                    </TechIcon>
                  ))}
                </GridPanel>
              </TechStackDetail>
            ))}
          </ServiceTabDetail>
        </TechStackWrapper>
      </TechnologiesWrapper>
    </div>
  );
};

export default TechnologiesSection;
