import React from "react";
import { useParams } from "react-router";
import { PORTFOLIO_DETIL } from "./constants";
import Header from "../../../landing-page/header";
import PortfolioDetailComponent from "./detail";
import Footer from "../../../landing-page/footer";
import { PortfolioItemDetailContainer } from "./style";

const PortfolioDetail = () => {
  const { id } = useParams();
  const data = PORTFOLIO_DETIL[id] ?? null;
  if (!data) {
    return (
      <div style={{ backgroundColor: "#1b1b1b"}}>
        <Header backgroundColor={"#1b1b1b"} />
        <PortfolioItemDetailContainer />
        <Footer />
      </div>
    );
  }
  return (
    <div style={{ backgroundColor: "#1b1b1b"}}>
      <Header backgroundColor={"#1b1b1b"} />
      <PortfolioDetailComponent />
      <Footer />
    </div>
  );
};

export default PortfolioDetail;
