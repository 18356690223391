import React from "react";
import Mail from "@mui/icons-material/Mail";
import Call from "@mui/icons-material/Call";
import FormComponent from "./form";
import {
  ContactUsSectionWrapper,
  LeftSection,
  DescriptionTitle,
  DescriptionLabel,
  RightSection,
  RightSectionTitle,
} from "./style";

const ContactUs = () => {
  return (
    <ContactUsSectionWrapper>
      <LeftSection>
        <DescriptionTitle>Contact Us</DescriptionTitle>
        <DescriptionLabel>
          Not sure what you need? The team at TechAbes will be happy to listen
          to you and suggest event ideas you hadn’t considered
        </DescriptionLabel>
        <br />
        <br />
        {/* <br /> */}
        <DescriptionLabel>
          <Mail /> info@techabes.com
        </DescriptionLabel>
        <DescriptionLabel>
          <Call /> Support: +91- 9876543210
        </DescriptionLabel>
      </LeftSection>
      <RightSection>
        <RightSectionTitle>
          We’d love to hear from you! <br /> Let’s get in touch
        </RightSectionTitle>
        <FormComponent />
      </RightSection>
    </ContactUsSectionWrapper>
  );
};

export default ContactUs;
