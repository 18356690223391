import styled from "styled-components";
import { SectionWrapper } from "../../common-style";

export const WorkLifeSectionWrapper = styled(SectionWrapper)``;

export const TitleWrapper = styled.h2`
  line-height: 1.1;
  text-align: center;
  color: #333;
  font-size: 60px;
  position: relative;
  z-index: 1;
  margin: 0;
  @media only screen and (max-width: 1900px) {
    font-size: 40px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 36px;
  }
  br {
    display: block;
  }
  span {
    font-size: 55px;
    position: absolute;
    bottom: 0;
    margin: 0 0 0 15px;
    @media only screen and (max-width: 768px) {
      font-size: 42px;
      bottom: -2px;
      margin: 0 0 0 6px;
    }
  }
`;

export const WorkLifeItemsContainer = styled.div`
  display: flex;
  width: 100%;
  margin: 0 auto;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`;

export const WorkLifeItemsContainerItem = styled.div`
  width: 33.333%;
  text-align: center;
  padding: 15px 0;
  font-size: 18px;
  position: relative;
  color: #6d6c6c;
  margin: 35px 0;
  display: flex;
  justify-content: center;
  &:not(:nth-child(3n + 1), :nth-child(9)):before {
    background: #707070;
    position: absolute;
    content: "";
    left: 0;
    top: 10px;
    width: 1px;
    height: 80px;
  }
  @media only screen and (max-width: 1024px) {
    width: 50%;
    padding: 15px 0;
    margin: 10px 0;
    &:nth-child(n):before {
      display: none;
    }
    &:nth-child(9) {
      display: none;
    }
  }
  @media only screen and (max-width: 500px) {
    width: 100%;
  }
`;

export const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    gap: 8px;
  }
  .data {
    width: 50%;
    text-align: left;
    color: #000;
    @media only screen and (max-width: 1024px) {
      margin: 0 15px;
      font-size: 13px;
      width: 60%;
    }
    @media only screen and (max-width: 768px) {
      width: 100%;
      text-align: center;
    }
    br {
      display: block;
    }
  }
`;

export const ImageWrapper = styled.div`
  width: 220px;
  @media only screen and (max-width: 1024px) {
    width: 40px;
  }
  img {
    transform: scale(1);
    backface-visibility: hidden;
    text-align: center;
    display: flex;
    margin: 0 auto;
    @media only screen and (max-width: 1024px) {
      margin: 0;
      max-width: 100%;
    }
  }
`;
