import React from "react";
import Header from "../../../landing-page/header";
import PortfolioDetailsList from "./details";
import Footer from "../../../landing-page/footer";

const PortfolioList = () => {
  return (
    <>
      <Header />
      <PortfolioDetailsList />
      <Footer />
    </>
  );
};

export default PortfolioList;
