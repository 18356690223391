import React from "react";
import Header from "../../../landing-page/header";
import BlogsContainerComponent from "../../../landing-page/blogs";
import Footer from "../../../landing-page/footer";

const BlogsList = () => {
  return (
    <>
      <Header backgroundColor={"#1b1b1b"} />
      <div>
        <BlogsContainerComponent showButton={false} isLandingPage={false} />
      </div>
      <Footer />
    </>
  );
};

export default BlogsList;
