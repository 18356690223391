export const List = [
  {
    message: `Working with Techabes has been a game-changer for our e-commerce platform. Their team took our vision and transformed it into a seamless online shopping experience that is both scalable and user-friendly. The attention to detail, especially with our product filters and checkout process, was outstanding. Our conversion rates have never been higher!`,
    clientImg: "/Imagetestimonial-1.svg",
    clientName: "Alyssa M.",
    clientCompany: "Founder of ShopEase",
  },
  {
    message: `Techabes helped us develop a robust mobile app that connects patients and doctors with just a few taps. The integration of secure telemedicine features was essential for our business, and they delivered beyond expectations. Their deep understanding of healthcare compliance made the entire process smooth and efficient. Highly recommend!`,
    clientImg: "Imagetestimonial-2.svg",
    clientName: "Dr. James T.",
    clientCompany: "CEO of MedLink Health",
  },
  {
    message: `Our on-demand service app was a complex project, but Techabes handled it with precision and creativity. They helped us streamline our booking system and integrated real-time GPS tracking, which has significantly improved user satisfaction. The feedback from our customers has been incredible, and we owe it all to Techabes' dedication to quality and innovation.`,
    clientImg: "Imagetestimonial-3.svg",
    clientName: "Emily R.",
    clientCompany: "COO of RideNow",
  },
  {
    message: `Techabes transformed our outdated property management system into a sleek, cloud-based dashboard that’s easy to use for both our team and clients. Their ability to simplify the complex workflow of real estate transactions and make it intuitive was impressive. The new system has saved us countless hours and significantly reduced errors. Couldn’t have asked for a better partner!`,
    clientImg: "Imagetestimonial-4.svg",
    clientName: "Michael L.",
    clientCompany: "Managing Director at Prime Properties",
  },
  {
    message: `Techabes brought our gaming concept to life with stunning design and smooth gameplay. Their experience in AR/VR development was crucial for our project, and they delivered a product that exceeded both our expectations and those of our players. We now have a strong foundation for future expansion, thanks to their expertise and innovative approach.`,
    clientImg: "Imagetestimonial-5.svg",
    clientName: "Liam J.",
    clientCompany: "Co-founder of NextGen Games",
  },
];
