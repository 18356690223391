import styled from "styled-components";
import { SectionWrapper, Title } from "../../common-style";

export const HistorySectionWrapper = styled(SectionWrapper)`
  color: #000;
  margin-bottom: 50px;
`;

export const HeadingTitle = styled(Title)`
  color: #fc6600;
  font-size: 48px;
  @media only screen and (max-width: 768px) {
    font-size: 32px;
  }
`;

export const HistoryDetails = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  @media only screen and (max-width: 768px) {
    flex-direction: column-reverse;
    align-items: center;
    gap: 20px;
    margin-bottom: 0;
  }
`;

export const HistoryLeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;
  gap: 15px;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const HistoryRightContainer = styled.div`
  width: 45%;
  @media only screen and (max-width: 500px) {
    width: 100%;
  }
`;

export const HistoryTitle = styled.div`
  font-size: 28px;
  font-weight: 600;
  @media only screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

export const HistoryDescription = styled.div`
  font-size: 20px;
  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }

`;

export const HistoryTabWrapper = styled.div`
  margin-top: 15px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  text-align: center;
  position: relative;
  &::after {
    content: "";
    background-color: #fc6600;
    width: 98%;
    height: 1px;
    left: 1%;
    position: absolute;
  }
`;

export const HistoryTabItem = styled.div`
  margin-top: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  left: ${({ index}) => `${(index * 31.5)}%`};
  @media only screen and (max-width: 1024px) {
    left: ${({ index}) => `${(index * 31)}%`};
  }
  @media only screen and (max-width: 768px) {
    left: ${({ index}) => `${(index * 29.5)}%`};
  }
`;

export const TabLabel = styled.div`
  font-size: 28px;
  line-height: 1.3;
  color: ${({ isSelected }) => (isSelected ? "#fc6600" : "#000")};
  @media only screen and (max-width: 768px) {
    font-size: 15px;
    font-weight: 500;
  }
  span {
    position: absolute;
  }
`;

export const TabIcon = styled.img`
  cursor: pointer;
  font-size: 18px;
  z-index: 2;
  height: 75px;
  width: 75px;
  position: absolute;
  top: -75px;
  border-radius: 50%;
  padding: 0;
  @media only screen and (max-width: 768px) {
    width: 30px;
    height: 30px;
    top: -35px;
  }
`;
