import styled from "styled-components";

export const ClientWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: #fff;
  position: relative;
  overflow: hidden;

  max-width: 1700px;
  width: 100%;
  margin: 20px auto 0 auto;
  padding: 80px 15px;
  @media only screen and (max-width: 1900px) {
    max-width: 1440px;
  }
  @media only screen and (max-width: 1550px) {
    max-width: 1340px;
  }
  @media only screen and (max-width: 1440px) {
    max-width: 1180px;
  }
  @media only screen and (max-width: 1280px) {
    margin: 0;
    max-width: calc(100% - 30px);
  }
`;

export const ClientText = styled.div`
  margin: 15px auto 0 auto;
  font-size: 16px;
  color: #a6aaae;
  display: block;
  padding: 10px 0 30px;
  text-align: center;

  &::before {
    width: 100%;
    z-index: 0;
    left: 0;
    position: absolute;
    content: "";
    margin-top: 10px;
    /* top: 20px; */
    height: 1px;
    background-color: #555;
    opacity: 0.5;
  }
  span {
    position: relative;
    background-color: #000;
    display: inline-block;
    padding: 0 45px;
  }
`;

export const ClientList = styled.div`
  display: flex;
  .rfm-marquee-content {
    display: flex;
    white-space: nowrap; /* Prevents content from wrapping */
    animation: marquee 30s linear 0s infinite;
    animation-play-state: running;
    animation-delay: 0s;
    @keyframes marquee {
      from {
        transform: translateX(0%); /* Start position */
      }
      to {
        transform: translateX(-100%); /* End position */
      }
    }
  }
`;

export const ImageContainer = styled.div`
  width: 310px;
  border-right: 1px solid #fff;
  padding: 0 40px;
  margin: 0 auto;
  box-sizing: border-box;
`;

export const BrandSectionItem = styled.img`
  height: 128px;
  max-width: 350px;
  display: flex;
  align-items: center;
  margin: 0 auto;
  @media only screen and (max-width: 1280px) {
    max-width: 200px;
    height: 100px;
  }
  @media only screen and (max-width: 500px) {
    max-width: 100px;
    height: 60px;
    padding: 0 20px;
  }
`;
