import styled from "styled-components";
import { SectionWrapper, Title, Description } from '../../../common-style';

export const TechnologiesWrapper = styled(SectionWrapper)`
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 15px;
`;

export const Heading = styled(Title)`
  color: #000;
  @media only screen and (max-width: 768px) {
    br {
      display: none;
    }
  }
`;

export const SubHeading = styled(Description)`
  color: #000;
  @media only screen and (max-width: 768px) {
    br {
      display: none;
    }
  }
`;


export const ChipWrapper = styled.div`
  position: relative;
  z-index: 3;
  display: flex;
  padding: 50px 0;
  overflow: hidden;
  white-space: nowrap;
  cursor: grab;
  @media only screen and (max-width: 768px) {
    padding:  0;
    overflow-x: scroll;
    white-space: unset;
    height: fit-content;
    width: auto;
    padding-bottom: 10px;
  }
`;

export const ChipItem = styled.div`
  margin: 0 10px;
  @media only screen and (max-width: 650px) {
    min-width: 150px;
    text-align: center;
  }
  .isActive {
    background-color: #fc6600;
    border-radius: 5px;
  }
`;

export const TabChip = styled.div`
  span {
    border: 2px solid ${({ isActive }) => (isActive ? "#fc6600" : "#000")};
    border-radius: 5px;
    padding: 0 35px;
    height: 50px;
    text-align: center;
    font-size: 17px;
    line-height: 1.2;
    font-weight: 500;
    color: ${({ isActive }) => (isActive ? "#fff" : "#000")};
    cursor: pointer;
    display: flex;
    align-items: center;
    @media only screen and (max-width: 768px) {
      font-size: 12px;
      justify-content: center;
    }
  }
`;

export const TechStackWrapper = styled.div`
  max-width: 950px;
  width: 100%;
  margin: 20px auto 0 auto;
`;

export const ServiceTabDetail = styled.div``;

export const TechStackDetail = styled.div`
`;

export const GridPanel = styled.div`
  display: flex;
  grid-row-gap: 4rem;
  justify-content: center;
  @media only screen and (max-width: 768px) {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
  }
`;

export const TechIcon = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 25%;
  figure {
    background: #e5e5e5;
    border-radius: 15px;
    width: 110px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media only screen and (max-width: 768px) {
      width: 85px;
      height: 85px;
    }
  }
  p {
    display: block;
    text-align: center;
    color: #000;
    font-size: 14px;
    line-height: 1.3;
  }
`;
