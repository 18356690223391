import React from "react";
import Header from "../../../landing-page/header";
import DetailsPage from "./details";
import Footer from "../../../landing-page/footer";

const BlogsDetails = () => {
  return (
    <div style={{ backgroundColor: "#1b1b1b"}}>
      <Header backgroundColor={"#1b1b1b"} />
      <DetailsPage />
      <Footer />
    </div>
  );
};

export default BlogsDetails;
