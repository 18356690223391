import React from "react";
import Header from "./header";
import Banner from "./banner";
import AboutUs from "./about-us";
import Services from "./services";
import Brands from "./brands";
import Certification from "./certification";
import CaseStudies from "./CaseStudies";
import ClientTestimonials from "./testimonials";
import BlogsContainerComponent from "./blogs";
import ContactUs from "./contact-us";
import TechnologiesComponent from "./technologies";
import Footer from "./footer";
import FAQ from "./faqs";

const LandingPage = () => {
  return (
    <>
      <Header />
      <Banner />
      <AboutUs />
      {/* <Brands /> */}
      <Services />
      <TechnologiesComponent />
      <Certification />
      <CaseStudies />
      <ClientTestimonials />
      <BlogsContainerComponent isLandingPage={true} />
      <ContactUs />
      <FAQ />
      <Footer />
    </>
  );
};

export default LandingPage;
