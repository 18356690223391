import styled from "styled-components";
import { SectionWrapper, Title, Description, TalkToExpertButton } from '../../../common-style';

export const BannerWrapper = styled(SectionWrapper)`
  position: relative;
  @media only screen and (max-width: 768px) {
    padding-top: 80px;
  }
  .section_banner {
    border-radius: 20px;
    width: 100%;
    max-height: 800px;
     /* object-fit: cover; */
    object-fit: cover;
    aspect-ratio: 5/2;
  }

  @media only screen and (max-width: 768px) {
    position: relative;
    padding-top: 0;
    transform: none;
    top: 0;
    left: 0;
  }
`;

export const ImageDataContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 50px;
  margin-top: 100px;
  color: #fff;
  z-index: 2;
  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 10px;
  }
`;

export const ImageDataContainerTitle = styled(Title)`
  display: flex;
  @media only screen and (max-width: 1024px) {
    br {
      display: none;
    }
  }
`;

export const ImageSubText = styled(Description)`
  @media only screen and (max-width: 1024px) {
    br {
      display: none;
    }
  }
`;

export const ImageButton = styled(TalkToExpertButton)``;
