import React from "react";
import Header from "../../landing-page/header";
import Footer from "../../landing-page/footer";
import FAQ from "../../landing-page/faqs";
import FormComponentDetail from "./form";
import {
  ContactUsDetailContainer,
  ContactUsWrapper,
  LeftWrapper,
  LeftTtile,
  SubTitle,
  RightWrapperContainer,
  RightWrapper,
  RightTitle,
} from "./style";

const ContactUsDetail = () => {
  return (
    <div style={{ backgroundColor: "#1b1b1b"}}>
      <Header backgroundColor={"#1b1b1b"} />
      <ContactUsDetailContainer>
        <ContactUsWrapper>
          <LeftWrapper>
            <LeftTtile>Kickstart Your Digital Journey Today</LeftTtile>
            <SubTitle>
              Get all your questions answered by our business
              development team.
            </SubTitle>
          </LeftWrapper>
          <RightWrapperContainer>
            <RightTitle>
              Request a proposal
            </RightTitle>
            <RightWrapper>
              <FormComponentDetail />
              <div>
                <img src="https://d29z5i6uc22g11.cloudfront.net/imagecontact-us.svg" alt="" width={'100%'} />
              </div>
            </RightWrapper>
          </RightWrapperContainer>
        </ContactUsWrapper>
      </ContactUsDetailContainer>
      <FAQ />
      <Footer />
    </div>
  );
};

export default ContactUsDetail;