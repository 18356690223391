import styled from "styled-components";
import { SectionWrapper, Title, Description } from '../../../common-style';

export const ServicesInfoWrapper = styled(SectionWrapper)``;

export const ServicesInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const ServicesInfoHeading = styled(Title)``;

export const ServicesInfoSubHeading = styled(Description)``;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
  margin-top: 30px;
  @media only screen and (max-width: 768px) {
    margin-top: 0;
    gap: 10px;
  }
`;

export const CardItem = styled.div`
  gap: 20px;
  display: grid;
  grid-template-columns: 45% 55%;
  border-top: 1px solid white;
  padding: 20px 0;
  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const CardTitle = styled.div`
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.5;
  @media only screen and (max-width: 1550px) {
    font-size: 20px;
  }
  @media only screen and (max-width: 768px) {
    line-height: 1.2;
  }
`;


export const CardInfo = styled.div`
  font-size: 16px;
  line-height: 1.5;
  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
`;
