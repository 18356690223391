import React from "react";
import { useParams } from "react-router";
import { SERVICES_INFO } from "../../constants";
import {
  ServicesInfoWrapper,
  ServicesInfoContainer,
  ServicesInfoHeading,
  ServicesInfoSubHeading,
  CardContainer,
  CardItem,
  CardTitle,
  CardInfo,
} from "./style";

const ServiceInfo = () => {
  const { id } = useParams();
  const data = SERVICES_INFO[id] ?? null;
  return (
    <div style={{ backgroundColor: "#1b1b1b" }}>
      <ServicesInfoWrapper>
        <ServicesInfoContainer>
          <ServicesInfoHeading
            dangerouslySetInnerHTML={{ __html: data.title }}
          />
          <ServicesInfoSubHeading
            dangerouslySetInnerHTML={{ __html: data.subTitle }}
          />
          <CardContainer>
            {data.list.map((item) => (
              <CardItem>
                <CardTitle>
                  {item.title}
                </CardTitle>
                <CardInfo>
                  {item.subTitle}
                </CardInfo>
              </CardItem>
            ))}
          </CardContainer>
        </ServicesInfoContainer>
      </ServicesInfoWrapper>
    </div>
  );
};

export default ServiceInfo;
