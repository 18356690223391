import React from "react";
import TechHeader from "./sections/header";
import ServicesProvided from "./sections/services-provided";
import ApplicationInfo from "./sections/application-info";
import WhyUsSection from "./sections/why-us-section";
import { DetailsWrapper } from "./style";

const TechDetails = () => {
  return (
    <DetailsWrapper>
      <TechHeader />
      <ServicesProvided />
      <ApplicationInfo />
      <WhyUsSection />
    </DetailsWrapper>
  );
};

export default TechDetails;
