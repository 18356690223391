import styled from "styled-components";
import { SectionWrapper } from '../common-styles';

export const AboutUsSectionWrapper = styled(SectionWrapper)`
  display: grid;
  grid-template-columns: 28% auto;
  @media only screen and (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
`;

export const AboutUsLeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 1024px) {
    max-width: 100%;
    flex-direction: column;
  }
`;

export const AboutUsItemWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  pointer-events: none;
  padding-right: 20px;
  margin-top: 80px;
  @media only screen and (max-width: 1440px) {
    margin-top: 50px;
  }
  @media only screen and (max-width: 1024px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
  @media only screen and (max-width: 900px) {
    justify-content: flex-start;
    box-sizing: border-box;
  }
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const AboutUsItemCardDataWrapper = styled.div`
  border-left: 1px solid white;
  height: 90px;
  position: relative;
  margin-top: 48px;
  @media only screen and (max-width: 1280px) {
    margin-right: 60px;
    margin-top: 0;
    margin-bottom: 40px;
    width: 150px;
  }
  @media only screen and (max-width: 620px) {
    margin-right: 50px;
    margin-top: 0;
    margin-bottom: 40px;
  }
`;

export const AboutUsItemCard = styled.div`
  border-top: 1px solid white;
  width: 30px;
`;

export const AboutUsItemCardTitle = styled.div`
  position: absolute;
  color: #fff;
  top: -9px;
  margin-left: 40px;
  font-size: 16px;
`;

export const AboutUsItemCardValue = styled.div`
  color: #fc6600;
  font-size: 72px;
  margin-left: 20px;
  margin-top: 5px;
`;

export const AboutUsItemCardValueSmall = styled.div`
  color: #fff;
  margin-left: 20px;
  position: absolute;
  left: 80px;
  font-size: 16px;
`;

export const AboutUsDescripitonWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DescriptionTitle = styled.div`
  margin-bottom: 20px;
  font-size: 28px;
  font-weight: 600;
  color: #fc6600;
  line-height: 1.5;
  @media only screen and (max-width: 1900px) {
    font-size: 24px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 16px;
    margin-bottom: 15px;
  }
`;

export const DescriptionLabel = styled.div`
  font-size: 22px;
  line-height: 24px;
  color: #fff;
  margin-bottom: 25px;
  line-height: 1.5;
  @media only screen and (max-width: 1900px) {
    font-size: 18px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
`;

export const ImageContainer = styled.div``;

export const ImageItem = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  max-height: 600px;
`;
