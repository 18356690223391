import React from "react";
import { useParams } from "react-router";
import { Description } from '../../common-style';
import { PORTFOLIO_DETIL } from "./constants";
import {
  PortfolioItemDetailContainer,
  HeaderContainer,
  HeaderLeft,
  TitleContainer,
  TextTitleContainer,
  HeaderRight,
  ItemTitle,
  ItemSubTitle,
  InfoContainer,
  InfoWrapper,
  InfoWrapperTitle,
  InfoWrapperSubtitle,
  TextContainer,
  TextContainerTitle,
  TextContainerInfo,
  ImagesContainer,
  IdeaInfoContainer,
  IdeaInfoTitle,
  ChallengeContainer,
  ChallengeItemContainer,
  ChallengeItem,
  ChallengeDivider,
  ChallengeNumber,
  ChallengeContent,
  ChallengeTitle,
  ChallengeInfo,
} from "./style";

const PortfolioDetailComponent = () => {
  const { id } = useParams();
  const data = PORTFOLIO_DETIL[id] ?? null;
  const isMobile = window.innerWidth <= 768;
  return (
    <PortfolioItemDetailContainer>
      <HeaderContainer>
        <HeaderLeft>
          <TitleContainer>
            <ItemTitle>{data.heading.title}</ItemTitle>
            <ItemSubTitle
              dangerouslySetInnerHTML={{ __html: data.heading.subTitle }}
            />
          </TitleContainer>
          <InfoContainer>
            {data.heading.info.map((item, index) => (
              <InfoWrapper key={index}>
                <InfoWrapperTitle>{item.title}</InfoWrapperTitle>
                <InfoWrapperSubtitle
                  dangerouslySetInnerHTML={{ __html: item.info }}
                />
              </InfoWrapper>
            ))}
          </InfoContainer>
        </HeaderLeft>
        <HeaderRight>
          <img
            src={data.heading.image}
            alt=""
            width={"100%"}
            style={{ borderRadius: "8px" }}
          />
        </HeaderRight>
      </HeaderContainer>
      <TextContainer>
        <TextTitleContainer>
          <Description style={{ color: "#fc6600"}}>
            About
          </Description>
          <TextContainerTitle
            dangerouslySetInnerHTML={{ __html: data.aboutUs.title }}
          />
        </TextTitleContainer>
        <TextContainerInfo>{data.aboutUs.info}</TextContainerInfo>
      </TextContainer>
      <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#fff', borderRadius: "10px"}}>
      <ImagesContainer style={{ paddingBottom: '30px' }}>
        {data.inages.map((item) => (
          <figure>
            <img src={item} alt="" style={{ borderRadius: "8px" }} />
          </figure>
        ))}
      </ImagesContainer>
        <IdeaInfoContainer>
          <IdeaInfoTitle>
            {data.infoPartOne.title}
          </IdeaInfoTitle>
          <TextContainerInfo
            dangerouslySetInnerHTML={{ __html: data.infoPartOne.info }}
            color="#000"
          />
        </IdeaInfoContainer>
        <IdeaInfoContainer>
          <IdeaInfoTitle>
            Our Process
          </IdeaInfoTitle>
          <ImagesContainer>
            <figure>
              {isMobile ? <img src="https://d29z5i6uc22g11.cloudfront.net/process-mobile.svg" alt="" /> : <img src="https://d29z5i6uc22g11.cloudfront.net/Groupprocess.svg" alt="" />}
            </figure>
          </ImagesContainer>
        </IdeaInfoContainer>
        <ChallengeContainer>
          <IdeaInfoTitle>{data.challenges.title}</IdeaInfoTitle>
          <ChallengeItemContainer>
            {data.challenges.list.map((item, index) => (
              <ChallengeItem key={index}>
                <ChallengeContent>
                <ChallengeNumber>
                  0{index + 1}
                  <ChallengeDivider />
                </ChallengeNumber>
                  <ChallengeTitle>{item.title}</ChallengeTitle>     
                </ChallengeContent>
                <ChallengeInfo>{item.info}</ChallengeInfo>
              </ChallengeItem>
            ))}
          </ChallengeItemContainer>
        </ChallengeContainer>

        <IdeaInfoContainer hasBorderRadius={true}>
          <IdeaInfoTitle>
            {data.processDevelopment.title}
          </IdeaInfoTitle>
          <TextContainerInfo
            dangerouslySetInnerHTML={{ __html: data.processDevelopment.info }}
            color="#000"
          />
        </IdeaInfoContainer>
      </div>
    </PortfolioItemDetailContainer>
  );
};

export default PortfolioDetailComponent;
