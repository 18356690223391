import styled from "styled-components";
import { SectionWrapper } from '../common-styles';

export const FAQSectionWrapper = styled(SectionWrapper)``;

export const DescriptionTitle = styled.div`
  margin: 10px 0 15px 0;
  font-size: 26px;
  font-weight: 600;
  color: #fc6600;
  line-height: 1.3;
  @media only screen and (max-width: 1280px) {
    font-size: 24px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 20px;
  }
`;

export const ListWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 50px;
  grid-row-gap: 50px;
  padding: 20px 0 0;
  @media only screen and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 0;
    grid-column-gap: 0;
  }
`;

export const ListItem = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  background-color: #fff;
  color: #000;
  border-radius: 5px;
  margin-bottom: 25px;
  .faq-content-wrap {
    padding-right: 40px;
    ul {
      margin-top: 0;
      margin-bottom: 10px;
      li {
        border: none;
        position: relative;
        padding: 0 0 0 20px;
        padding-bottom: 10px;
        font-size: 14px;
        line-height: 1.5;
        color: #fc6600;;
        list-style-type: none;
        &::before {
          position: absolute;
          content: "";
          background-color: #fc6600;
          border-radius: 50%;
          width: 6px;
          height: 6px;
          left: 0;
          top: 7px;
        }

        a {
          text-decoration: none !important;
          border: 0;
          outline: 0 !important;
          color: #fc6600;
        }
      }
    }
  }
`;

export const Question = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
  padding: 20px 60px 25px 20px;
  margin: 0;
  cursor: pointer;
  background-color: #fff;
  border-radius: ${({ isOpen }) => isOpen ? '5px 5px 0 0' : '5px'};
  color: #000;
  position: relative;
  &:hover {
    background-color: ${({ isOpen }) => isOpen ? '#fff' : '#fc6600'};
    color: ${({ isOpen }) => isOpen ? '#000' : '#fff'};
    border-radius: ${({ isOpen }) => isOpen ? '5px 5px 0 0' : '5px'};
  }

  svg {
    position: absolute;
    right: 20px;
    top: 15px;
    width: 36px;
    height: 36px;
    opacity: 1;
    transform: ${({ isOpen }) => (isOpen ? `rotate(180deg)` : `rotate(0deg)`)};
  }
`;

export const Answer = styled.div`
  display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
  border-radius: 0 0 5px 5px;
  padding: 20px 40px;
  background-color: #fff;
  color: #000;
  .para {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.6;
    margin: 0 0 20px;

    li {
      text-decoration: none !important;
      border: 0;
      outline: 0 !important;
      color: #fc6600;
    }
  }
`;
