export const SERVICES_DETAILS = {
  mobile: {
    landingImage: {
      image: "/mobile_service.svg",
      titleText:
        "Transforming Ideas into Mobile Experiences",
      subText:
        "Our mobile development services focus on creating intuitive, high-performance apps that connect with users across all devices. Whether native, hybrid, IoT-based, or AR/VR, we bring your vision to life with innovative solutions tailored to your business needs.",
      buttonText: "Talk to an Expert",
    },
  },
  web: {
    landingImage: {
      image: "/Web_Solutions_service.png",
      titleText:
        "Building the Web, One Pixel at a Time",
      subText:
        "Our web development services deliver fast, scalable, and feature-rich web applications tailored to your business. From dynamic single-page apps to full-scale e-commerce platforms, we craft solutions that are secure, responsive, and user-centric.",
      buttonText: "Talk to an Expert",
    },
  },
  design: {
    landingImage: {
      image: "/UI_UX_service.png",
      titleText:
        "Designs That Engage, Inspire, and Convert",
      subText:
        "Our design services focus on creating visually stunning and user-centric designs that enhance brand identity and drive customer engagement. From mobile and web design to complete brand overhauls, we deliver designs that align with your vision and business objectives.",
      buttonText: "Talk to an Expert",
    },
  },
  "start-up": {
    landingImage: {
      image: "/startup_service.png",
      titleText:
        "Empowering Startups to Scale and Succeed",
      subText:
        "Our startup accelerator services are designed to help new ventures take off with confidence. From ideation to launch, we provide end-to-end support, offering development, design, and business strategy to help startups grow, scale, and achieve their goals.",
      buttonText: "Talk to an Expert",
    },
  },
};

export const SERVICES_DESCRIPTIONS = {
  mobile: {
    title: "Pioneering Mobile-First Design Services We Offer",
    subTitle:
      "In an increasingly handheld world, we craft captivating digital experiences that resonate with your audience. Ensuring smooth delivery of digital assets across platforms, devices, and screens, we optimize the impact of your online presence by reaching your target audience on their preferred   devices and platforms. Here are the mobile-first services we offer.",
    features: [
      {
        title: "Native App Development",
        imageSrc:
          "https://d29z5i6uc22g11.cloudfront.net/native_app_development.svg",
        description:
          "Platform-specific apps for iOS (Swift) and Android (Kotlin/Java) that utilize the full potential of native technologies, ensuring high performance and seamless user experiences.",
      },
      {
        title: "Hybrid App Development",
        imageSrc:
          "https://d29z5i6uc22g11.cloudfront.net/native_app_development.svg",
        description:
          "Cross-platform solutions using React Native and Flutter that reduce development time and costs while delivering consistent performance and design across platforms.",
      },
      {
        title: "IoT Integration",
        imageSrc:
          "https://d29z5i6uc22g11.cloudfront.net/native_app_development.svg",
        description:
          "Creating mobile solutions that connect seamlessly with IoT devices, enhancing user interaction, data management, and real-time control for industries like home automation, healthcare, and logistics.",
      },
      {
        title: "AR/VR Apps",
        imageSrc:
          "https://d29z5i6uc22g11.cloudfront.net/native_app_development.svg",
        description:
          "Immersive augmented reality (AR) and virtual reality (VR) experiences for gaming, e-commerce, education, and more, built with tools like ARKit, ARCore, and Unity.",
      },
      {
        title: "TV Apps",
        imageSrc:
          "https://d29z5i6uc22g11.cloudfront.net/native_app_development.svg",
        description:
          "Build smart TV applications for platforms like Android TV, Apple TV, and Roku to expand your digital presence to the living room.",
      },
      {
        title: "Mobile Game Development",
        imageSrc:
          "https://d29z5i6uc22g11.cloudfront.net/native_app_development.svg",
        description:
          "Custom game development for mobile platforms with engaging user interfaces and immersive gameplay.",
      },
    ],
  },
  web: {
    title: "Pioneering Mobile-First Design Services We Offer",
    subTitle:
      "In an increasingly handheld world, we craft captivating digital experiences that resonate with your audience. Ensuring smooth delivery of digital assets across platforms, devices, and screens, we optimize the impact of your online presence by reaching your target audience on their preferred devices and platforms. Here are the mobile-first services we offer.",
    features: [
      {
        title: "Frontend Development",
        imageSrc:
          "https://d29z5i6uc22g11.cloudfront.net/native_app_development.svg",
        description:
          "Interactive user interfaces with modern frameworks like React, Angular, and Vue.js, ensuring responsive and visually appealing designs that work on all devices.",
      },
      {
        title: "Backend Development",
        imageSrc:
          "https://d29z5i6uc22g11.cloudfront.net/native_app_development.svg",
        description:
          "Scalable, secure server-side applications using Node.js, Python, Ruby, and PHP, designed to handle large data loads and support complex business logic.",
      },
      {
        title: "Full-Stack Development",
        imageSrc:
          "https://d29z5i6uc22g11.cloudfront.net/native_app_development.svg",
        description:
          "Complete solutions that integrate both frontend and backend, utilizing frameworks like MERN (MongoDB, Express.js, React, Node.js) and MEAN (MongoDB, Express.js, Angular, Node.js).",
      },
      {
        title: "Progressive Web Apps (PWAs)",
        imageSrc:
          "https://d29z5i6uc22g11.cloudfront.net/native_app_development.svg",
        description:
          "App-like experiences on the web with offline capabilities, fast load times, and push notifications for improved user engagement.",
      },
      {
        title: "E-commerce Platforms",
        imageSrc:
          "https://d29z5i6uc22g11.cloudfront.net/native_app_development.svg",
        description:
          "Custom-built e-commerce solutions with seamless payment gateways, shopping carts, and personalized user experiences.",
      },
      {
        title: "Custom CMS Development",
        imageSrc:
          "https://d29z5i6uc22g11.cloudfront.net/native_app_development.svg",
        description:
          "Tailored content management systems that give you full control over your website's content and functionality without requiring extensive technical expertise.",
      },
    ],
  },
  design: {
    title: "Pioneering Mobile-First Design Services We Offer",
    subTitle:
      "In an increasingly handheld world, we craft captivating digital experiences that resonate with your audience. Ensuring smooth delivery of digital assets across platforms, devices, and screens, we optimize the impact of your online presence by reaching your target audience on their preferred devices and platforms. Here are the mobile-first services we offer.",
    features: [
      {
        title: "Mobile App Design",
        imageSrc:
          "https://d29z5i6uc22g11.cloudfront.net/native_app_development.svg",
        description:
          "Clean, responsive designs that prioritize user experience, ensuring that every interaction is meaningful and engaging.",
      },
      {
        title: "Web Design",
        imageSrc:
          "https://d29z5i6uc22g11.cloudfront.net/native_app_development.svg",
        description:
          "Eye-catching, functional websites that are optimized for performance and user engagement.",
      },
      {
        title: "Brand Design",
        imageSrc:
          "https://d29z5i6uc22g11.cloudfront.net/native_app_development.svg",
        description:
          "Logo creation, brand guidelines, and marketing materials that build a strong, recognizable brand identity.",
      },
      {
        title: "UI/UX Design",
        imageSrc:
          "https://d29z5i6uc22g11.cloudfront.net/native_app_development.svg",
        description:
          "Data-driven user interface and experience design that ensures seamless user journeys and increased customer satisfaction.",
      },
      {
        title: "Wireframing & Prototyping",
        imageSrc:
          "https://d29z5i6uc22g11.cloudfront.net/native_app_development.svg",
        description:
          "Early-stage wireframes and interactive prototypes to validate designs before development.",
      },
    ],
  },
  "start-up": {
    title: "Pioneering Mobile-First Design Services We Offer",
    subTitle:
      "In an increasingly handheld world, we craft captivating digital experiences that resonate with your audience. Ensuring smooth delivery of digital assets across platforms, devices, and screens, we optimize the impact of your online presence by reaching your target audience on their preferred devices and platforms. Here are the mobile-first services we offer.",
    features: [
      {
        title: "MVP Development",
        imageSrc:
          "https://d29z5i6uc22g11.cloudfront.net/native_app_development.svg",
        description:
          "Rapid development of a Minimum Viable Product to test the market and gather early feedback, allowing you to iterate quickly.",
      },
      {
        title: "Business Strategy Consulting",
        imageSrc:
          "https://d29z5i6uc22g11.cloudfront.net/native_app_development.svg",
        description:
          "In-depth consulting services to help define your product, identify your market, and develop a plan for scaling your business.",
      },
      {
        title: "Growth Hacking & Marketing",
        imageSrc:
          "https://d29z5i6uc22g11.cloudfront.net/native_app_development.svg",
        description:
          "Innovative growth strategies that leverage data analytics, content marketing, and social media to drive user acquisition and retention.",
      },
      {
        title: "Investor Pitch Deck Development",
        imageSrc:
          "https://d29z5i6uc22g11.cloudfront.net/native_app_development.svg",
        description:
          "Craft compelling investor decks and funding proposals that resonate with potential backers.",
      },
    ],
  },
};

export const SERVICES_INFO = {
  mobile: {
    title: "Empowering Your Business with Mobile-First Excellence",
    subTitle:
      "Recognizing the need of the era, we have developed comprehensive expertise that helps you tap into this growing market by enabling seamless, integrated experiences across devices. We have been doing this with the help of the capabilities mentioned below.",
    list: [
      {
        title: "Drive Engagement with Intuitive Mobile Solutions",
        subTitle:
          "Our mobile apps are designed to engage users from the first tap, with user-friendly interfaces and smooth navigation that keeps customers coming back for more. We focus on creating an immersive experience that aligns with your brand and goals.",
      },
      {
        title: "Accelerate Growth with Scalable Mobile Platforms",
        subTitle:
          "We build mobile solutions that grow with your business. From startups to enterprise-level companies, we ensure that your app can handle increased user traffic and expanded functionality, helping you scale efficiently and cost-effectively.",
      },
      {
        title: "Optimize Operations with Integrated Mobile Technologies",
        subTitle:
          "Our mobile apps seamlessly integrate with your existing systems, enhancing operational efficiency. From real-time data processing to IoT integration, we empower your business to run smarter and faster, cutting down on manual processes and improving decision-making.",
      },
    ],
  },
  web: {
    title: "Empowering Your Business with Mobile-First Excellence",
    subTitle:
      "Recognizing the need of the era, we have developed comprehensive expertise that helps you tap into this growing market by enabling seamless, integrated experiences across devices. We have been doing this with the help of the capabilities mentioned below.",
    list: [
      {
        title: "Enhance Your Online Presence with Responsive Web Solutions",
        subTitle:
          "We craft websites that not only look great but work flawlessly across all devices. Our responsive designs ensure your customers have a seamless experience, whether they're browsing on a desktop, tablet, or smartphone.",
      },
      {
        title: "Boost Conversion Rates with Optimized Web Applications",
        subTitle:
          "Speed and performance are key to customer satisfaction. We optimize every aspect of your web application for fast load times, smooth interactions, and security, ensuring that visitors convert into loyal customers.",
      },
      {
        title: "Scale Efficiently with Robust Backend Infrastructure",
        subTitle:
          "Our web solutions are built to handle your growing business needs. With scalable server-side technologies, we ensure your web platform can handle high traffic, large data sets, and future expansion, all while maintaining peak performance.",
      },
    ],
  },
  design: {
    title: "Empowering Your Business with Mobile-First Excellence",
    subTitle:
      "Recognizing the need of the era, we have developed comprehensive expertise that helps you tap into this growing market by enabling seamless, integrated experiences across devices. We have been doing this with the help of the capabilities mentioned below.",
    list: [
      {
        title: "Captivate Your Audience with Stunning Visuals",
        subTitle:
          "Our design solutions are built to leave a lasting impression. Whether it's a mobile app, a website, or a complete brand overhaul, we focus on creating visuals that resonate with your target audience and enhance your brand identity.",
      },
      {
        title: "Boost Conversions with User-Centric Design",
        subTitle:
          "By understanding your users' needs and behaviors, we design interfaces that make it easy for them to take action. Our user-centric approach ensures a seamless journey, driving higher engagement and conversions.",
      },
      {
        title: "Build a Consistent, Memorable Brand Identity",
        subTitle:
          "Consistency is key to brand recognition. We help businesses create cohesive, recognizable branding across all digital touchpoints, ensuring that your brand remains top-of-mind for your customers.",
      },
    ],
  },
  "start-up": {
    title: "Empowering Your Business with Mobile-First Excellence",
    subTitle:
      "Recognizing the need of the era, we have developed comprehensive expertise that helps you tap into this growing market by enabling seamless, integrated experiences across devices. We have been doing this with the help of the capabilities mentioned below.",
    list: [
      {
        title: "Validate Ideas Quickly with MVPs",
        subTitle:
          "We help startups bring their ideas to life by developing MVPs that provide real-time feedback from users, allowing for fast iteration and growth. Our approach helps minimize risk and maximize potential success.",
      },
      {
        title: "Scale Fast with Strategic Consulting",
        subTitle:
          "Our startup accelerator services focus on creating strategies that allow you to scale your business effectively. We identify the best opportunities for growth and help you navigate the challenges of early-stage development.",
      },
      {
        title: "Get Investor-Ready with Targeted Pitch Decks",
        subTitle:
          "We craft pitch decks that not only present your business in the best light but also speak to what investors are looking for. By highlighting your startup’s unique value proposition and growth potential, we help you secure the funding you need to scale.",
      },
    ],
  },
};

export const TECHNOLOGIES_INFO = {
  mobile: {
    title:
      "Tools and Technologies We Use to <br /> Serve the Mobile-First Generation",
    subTitle:
      "Meet the tools in the arsenal of our designers, developers, and software engineers, which they <br /> use to enhance your service delivery across platforms and devices.",
    list: [
      {
        title: "Design Process",
        items: [
          {
            title: "Sketch",
            link: "https://d29z5i6uc22g11.cloudfront.net/tools/sketch.svg",
          },
          {
            title: "Figma",
            link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figurefigma.svg",
          },
          {
            title: "Adobe XD",
            link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figureadobe-xd.svg",
          },
        ],
      },
      {
        title: "UI/UX Frameworks & Libraries",
        items: [
          {
            title: "Material Design",
            link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figurematerial-design.svg",
          },
          {
            title: "Apple Human Interface Guidelines",
            link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figureapple-intelligence.svg",
          },
        ],
      },
      {
        title: "Frontend",
        items: [
          {
            title: "React Native",
            link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figurereact.svg",
          },
          {
            title: "Flutter",
            link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figureflutter.svg",
          },
        ],
      },
      {
        title: "Mobile",
        items: [
          {
            title: "Swift",
            link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figureswift.svg",
          },
          {
            title: "Kotlin",
            link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figurekotlin.svg",
          },
          {
            title: "Java",
            link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figurejava.svg",
          },
          {
            title: "Dart",
            link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figuredart.svg",
          },
        ],
      },
      {
        title: "Backend",
        items: [
          {
            title: "Node.js",
            link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figurenode.svg",
          },
          {
            title: "Firebase",
            link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figurefirebase.svg",
          },
          {
            title: "GraphQL",
            link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figuregraphql.svg",
          },
          {
            title: "Python",
            link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figurepython.svg",
          },
        ],
      },
      {
        title: "Performance Optimization",
        items: [
          {
            title: "ProGuard",
            link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figureproguard.svg",
          },
          {
            title: "Lint",
            link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figureeslint.svg",
          },
          {
            title: "Profilers (Xcode, Android Studio)",
            link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figureprofilers.svg",
          },
        ],
      },
      {
        title: "Version Control",
        items: [
          {
            title: "Git",
            link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figuregit.svg",
          },
          {
            title: "Bitbucket",
            link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figurebit-bucket.svg",
          },
        ],
      },
    ],
  },
  web: {
    title:
      "Tools and Technologies We Use to <br /> Serve the Mobile-First Generation",
    subTitle:
      "Meet the tools in the arsenal of our designers, developers, and software engineers, which they <br /> use to enhance your service delivery across platforms and devices.",
      list: [
        {
          title: "Design Process",
          items: [
            {
              title: "Sketch",
              link: "https://d29z5i6uc22g11.cloudfront.net/tools/sketch.svg",
            },
            {
              title: "Figma",
              link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figurefigma.svg",
            },
            {
              title: "Adobe XD",
              link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figureadobe-xd.svg",
            },
          ],
        },
        {
          title: "UI/UX Frameworks & Libraries",
          items: [
            {
              title: "Bootstrap",
              link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figurebootstrap.svg",
            },
            {
              title: "Tailwind CSS",
              link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figuretailwind.svg",
            },
            {
              title: "Material-UI",
              link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figurematerial-design.svg",
            },
          ],
        },
        {
          title: "Frontend",
          items: [
            {
              title: "React.js",
              link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figurereact.svg",
            },
            {
              title: "Angular",
              link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figureangular.svg",
            },
            {
              title: "Vue.js",
              link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figurevue.svg",
            },
          ],
        },
        {
          title: "Mobile-Friendly Tools",
          items: [
            {
              title: "Responsive design frameworks",
              link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figureresponsive-framework.svg",
            },
            {
              title: "PWAs",
              link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figurepwas.svg",
            },
          ],
        },
        {
          title: "Backend",
          items: [
            {
              title: "Node.js",
              link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figurenode.svg",
            },
            {
              title: "Python",
              link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figurepython.svg",
            },
            {
              title: "Ruby on Rails",
              link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figureruby-on-rails.svg",
            },
            {
              title: "PHP",
              link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figurephp.svg",
            },
            {
              title: "MongoDB",
              link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figuremongodb.svg",
            },
            {
              title: "MySQL",
              link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figuremysql.svg",
            },
          ],
        },
        {
          title: "Performance Optimization",
          items: [
            {
              title: "Webpack",
              link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figurewebpack.svg",
            },
            {
              title: "Lighthouse",
              link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figurelighthouse.svg",
            },
            {
              title: "Lazy loading",
              link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figurelazy-loading.svg",
            },
            {
              title: "CDN integration",
              link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figurecdn.svg",
            }
          ],
        },
        {
          title: "Version Control",
          items: [
            {
              title: "GitHub",
              link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figuregithub.svg",
            },
            {
              title: "Bitbucket",
              link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figurebit-bucket.svg",
            },
            {
              title: "GitLab",
              link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figuregitlab.svg",
            },
          ],
        },
      ],
  },
  design: {
    title:
      "Tools and Technologies We Use to <br /> Serve the Mobile-First Generation",
    subTitle:
      "Meet the tools in the arsenal of our designers, developers, and software engineers, which they <br /> use to enhance your service delivery across platforms and devices.",
    list: [
        {
          title: "Design Process",
          items: [
            {
              title: "Sketch",
              link: "https://d29z5i6uc22g11.cloudfront.net/tools/sketch.svg",
            },
            {
              title: "Figma",
              link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figurefigma.svg",
            },
            {
              title: "Adobe XD",
              link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figureadobe-xd.svg",
            },
            {
              title: "InVision",
              link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figureinvision.svg",
            },
          ],
        },
        {
          title: "UI/UX Frameworks & Libraries",
          items: [
            {
              title: "Material Design",
              link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figurematerial-design.svg",
            },
            {
              title: "Tailwind CSS",
              link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figuretailwind.svg",
            },
            {
              title: "Ant Design",
              link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figureantd.svg",
            },
          ],
        },
        {
          title: "Frontend",
          items: [
            {
              title: "HTML5",
              link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figurehtml5.svg",
            },
            {
              title: "CSS3",
              link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figurecss3.svg",
            },
            {
              title: "JavaScript (React, Angular)",
              link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figurejs.svg",
            },
          ],
        },
        {
          title: "Mobile",
          items: [
            {
              title: "React Native",
              link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figurereact.svg",
            },
            {
              title: "Flutter",
              link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figureflutter.svg",
            },
            {
              title: "SwiftUI",
              link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figureswift.svg",
            },
          ],
        },
        {
          title: "Backend (for design integration)",
          items: [
            {
              title: "Node.js",
              link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figurenode.svg",
            },
            {
              title: "Python",
              link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figurepython.svg",
            },
            {
              title: "PHP",
              link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figurephp.svg",
            },
          ],
        },
        {
          title: "Performance Optimization",
          items: [
            {
              title: "Image compression",
              link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figureimage-compression.svg",
            },
            {
              title: "SVG graphics",
              link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figuresvg-graphics.svg",
            },
            {
              title: "Lazy loading",
              link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figurelazy-loading.svg",
            },
          ],
        },
        {
          title: "Version Control",
          items: [
            {
              title: "Git",
              link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figuregit.svg",
            },
            {
              title: "Bitbucket",
              link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figurebit-bucket.svg",
            },
            {
              title: "Zeplin",
              link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figurezeplin.svg",
            },
          ],
        },
      ],
  },
  "start-up": {
    title:
      "Tools and Technologies We Use to <br />  Serve the Mobile-First Generation",
    subTitle:
      "Meet the tools in the arsenal of our designers, developers, and software engineers, which they <br /> use to enhance your service delivery across platforms and devices.",
      list: [
        {
          title: "Design Process",
          items: [
            {
              title: "Lean Canvas",
              link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figurelean-canvas.svg",
            },
            {
              title: "Product Hunt",
              link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figureproduct-hunt.svg",
            },
            {
              title: "Trello",
              link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figuretrello.svg",
            },
            {
              title: "Jira",
              link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figurejira.svg",
            },
          ],
        },
        {
          title: "UI/UX Frameworks & Libraries",
          items: [
            {
              title: "Figma",
              link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figurefigma.svg",
            },
            {
              title: "Sketch",
              link: "https://d29z5i6uc22g11.cloudfront.net/tools/sketch.svg",
            },
            {
              title: "Adobe XD",
              link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figureadobe-xd.svg",
            },
          ],
        },
        {
          title: "Frontend",
          items: [
            {
              title: "React.js",
              link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figurereact.svg",
            },
            {
              title: "Vue.js",
              link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figurevue.svg",
            },
            {
              title: "Angular",
              link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figureangular.svg",
            },
          ],
        },
        {
          title: "Mobile",
          items: [
            {
              title: "React Native",
              link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figurereact.svg",
            },
            {
              title: "Flutter",
              link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figureflutter.svg",
            },
            {
              title: "Swift",
              link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figureswift.svg",
            },
            {
              title: "Kotlin",
              link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figurekotlin.svg",
            },
          ],
        },
        {
          title: "Backend",
          items: [
            {
              title: "Node.js",
              link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figurenode.svg",
            },
            {
              title: "Firebase",
              link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figurefirebase.svg",
            },
            {
              title: "AWS Lambda",
              link: "https://appinventiv.com/wp-content/themes/twentynineteen-child/new-images/sketch-tech.svg",
            },
            {
              title: "Python",
              link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figurepython.svg",
            },
          ],
        },
        {
          title: "Performance Optimization",
          items: [
            {
              title: "A/B testing tools",
              link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figurea-b-testing.svg",
            },
            {
              title: "Google Analytics",
              link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figuregoogle-analystics.svg",
            },
            {
              title: "PageSpeed Insights",
              link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figurepage-insight.svg",
            },
          ],
        },
        {
          title: "Version Control",
          items: [
            {
              title: "GitHub",
              link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figuregithub.svg",
            },
            {
              title: "Bitbucket",
              link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figurebit-bucket.svg",
            },
            {
              title: "GitLab",
              link: "https://d29z5i6uc22g11.cloudfront.net/tools/Figuregitlab.svg",
            },
          ],
        },
      ],
  },
};
