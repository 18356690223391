export const PPORTFOLIO_LIST = [
  {
    imageSrc: "/go_cabs_heading.png",
    title: "On-Demand Service App (Cab Booking App)",
    info: "Our on-demand cab booking app revolutionizes the way users find and book rides. With seamless connectivity and user-friendly interfaces, we aim to provide a hassle-free travel experience.",
    content: [
      { title: "Transportation", info: "Industry" },
      { title: "Service", info: "Business Type" },
    ],
    link: "/portfolio/on-demand-service-app",
  },
  {
    imageSrc: "/glowify_heading.png",
    title: "Beauty App Named Glowify",
    info: "Glowify is a beauty app designed to connect users with beauty professionals, offering a range of services from skincare consultations to makeup tutorials, all from the convenience of their smartphones.",
    content: [
      { title: "Beauty", info: "Industry" },
      { title: "Service", info: "Business Type" },
    ],
    link: "/portfolio/beauty-app",
  },
  {
    imageSrc: "/crm_heading.png",
    title: "Content Management System (CMS)",
    info: "Our CMS provides a powerful and flexible solution for managing digital content, enabling businesses to create, edit, and publish content effortlessly.",
    content: [
      { title: "Software", info: "Industry" },
      { title: "Platform", info: "Business Type" },
    ],
    link: "/portfolio/crm-app",
  },
  {
    imageSrc: "/cryptoloop_heading.png",
    title: "Stock Management Dashboard",
    info: "Our stock management dashboard offers real-time inventory tracking, efficient stock control, and insightful analytics, empowering businesses to optimize their supply chain operations.",
    content: [
      { title: "Logistics", info: "Industry" },
      { title: "Enterprise", info: "Business Type" },
    ],
    link: "/portfolio/cryptoloop-app",
  },
  {
    imageSrc: "/e_commerece_heading.png",
    title: "E-commerce Website",
    info: "Our e-commerce website offers a seamless shopping experience with a wide range of products, easy navigation, and secure transactions, designed to meet the demands of modern consumers.",
    content: [
      { title: "Retail", info: "Industry" },
      { title: "E-commerce", info: "Business Type" },
    ],
    link: "/portfolio/glamify-me-app",
  },
  {
    imageSrc: "/social_media_heading.png",
    title: "Social Media Networking Website",
    info: "Our social media networking website connects users worldwide, fostering communication and community building through innovative features and a user-centric design.",
    content: [
      { title: "Social Networking", info: "Industry" },
      { title: "Platform", info: "Business Type" },
    ],
    link: "/portfolio/social-media-app",
  },
];
