import styled from "styled-components";

export const ImagesSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  /* max-width: 1700px; */
  /* margin: 0 auto; */

  /* @media only screen and (max-width: 1900px) {
    max-width: 1440px;
  }
  @media only screen and (max-width: 1550px) {
    max-width: 1340px;
  }
  @media only screen and (max-width: 1440px) {
    max-width: 1180px;
  }
  @media only screen and (max-width: 1280px) {
    max-width: calc(100% - 20px);
  } */
`;

export const Container = styled.div`
  display: grid;
  height: 100%;
  grid-gap: 0;
  grid-template-columns: repeat(auto-fit, minmax(12.5%, 1fr));
  grid-auto-flow: dense;
  background: #ccc;
  @media only screen and (max-width: 1500px) {
    grid-auto-rows: 220px;
  }
  @media only screen and (max-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(25%, 1fr));
    grid-auto-rows: 160px;
  }
  .small {
    grid-column: span 2;
  }
  .wide {
    grid-column: span 4;
  }
  .half {
  }
  .tall {
    grid-row: span 2;
  }
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
`;

export const ImageWrapper = styled.a`
  overflow: hidden;
  text-decoration: none !important;
  border: 0;
  outline: 0 !important;
  margin: 0 auto;
  padding: 0;
  transition: all ease 0.3s;
  color: #0092ff;
  img {
    margin: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
    transition: 0.3s;
    transform: scale(1);
    vertical-align: middle;
    &:hover {
      transform: scale(1.1);
    }
  }
`;
