import React from "react";
import { PPORTFOLIO_LIST } from "./constants";
import {
  PortfolioListDetailContainer,
  TitleContainer,
  TitleLeft2,
  TitleLeft3,
  CardsContainer,
  Card,
  ImageContainer,
  CardImg,
  CardInfoContainer,
  CardTitle,
  CardSubtitle,
  TechContainer,
  TechItem,
  TechItemTitle,
  TechItemDescription,
  Seperator,
  Button,
} from "./style";

const PortfolioDetailsList = () => {
  return (
    <PortfolioListDetailContainer>
      <TitleContainer>
        <TitleLeft3>Case Studies</TitleLeft3>
        <TitleLeft2>Optimize. Innovate. Disrupt</TitleLeft2>
        <TitleLeft3>
          Success stories that demonstrate our expertise to deliver
          technology-led business <br /> breakthroughs across startups, global
          brands, and Fortune 500s
        </TitleLeft3>
      </TitleContainer>
      <CardsContainer>
        {PPORTFOLIO_LIST.map((item) => (
          <Card className="item">
            <ImageContainer>
              <CardImg src={item.imageSrc} />
            </ImageContainer>
            <CardInfoContainer className="info">
              <CardTitle>{item.title}</CardTitle>
              <CardSubtitle>{item.info}</CardSubtitle>
              <TechContainer>
                {item.content?.map((contentItem, index) => (
                  <TechItem key={index}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <TechItemTitle>{contentItem.title}</TechItemTitle>
                      <TechItemDescription
                        dangerouslySetInnerHTML={{ __html: contentItem.info }}
                      />
                    </div>
                    {item.content.length - 1 !== index ? <Seperator /> : null}
                  </TechItem>
                ))}
              </TechContainer>
              <Button href={item.link}>View Case Study</Button>
            </CardInfoContainer>
          </Card>
        ))}
      </CardsContainer>
    </PortfolioListDetailContainer>
  );
};

export default PortfolioDetailsList;
