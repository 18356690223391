import React, { useRef } from "react";
import { useParams } from "react-router";
import { BLOGS_DETAILS } from "./constants";
import {
  BlogItemDetailContainer,
  ImageTitle,
  BlogsTitle,
  BlogsWrapper,
  LeftWrapper,
  RightWrapper,
  RightWrapperItem,
  RightItemTitle,
} from "./styles";

const DetailsPage = () => {
  const sectionRefs = useRef([]);
  const { id } = useParams();
  const data = BLOGS_DETAILS[id] ?? null;
  if (!data) {
    return <BlogItemDetailContainer />;
  }
  const handleHeadingClick = (index) => {
    sectionRefs.current[index].scrollIntoView({ behavior: 'smooth' });
  };
  return (
    <BlogItemDetailContainer>
      <BlogsTitle>{data.title}</BlogsTitle>
      <ImageTitle>
        <img
          src={data.image_src}
          alt=""
          width={"100%"}
        />
      </ImageTitle>
      <BlogsWrapper>
        <LeftWrapper>
          <ul>
            {Object.keys(data.info).map((title, index) => (
              <li>
                <span className="scroll_list_num" />
                <span className="scroll_list_text" onClick={() => handleHeadingClick(index)}>{title}</span>
              </li>
            ))}
          </ul>
        </LeftWrapper>
        <RightWrapper>
          {Object.keys(data.info).map((title, index) => (
            <>
              <RightItemTitle 
                ref={(el) => {
                  console.log(el);
                  sectionRefs.current[index] = el;
                }}
              >
                {title}
              </RightItemTitle>
              <RightWrapperItem>
                {data.info[title].detail}
              </RightWrapperItem>
              {!!data.info[title].content ? (
                <>
                  {data.info[title].content.map((item) => (
                    <>
                      <RightWrapperItem>
                        <span>{item.title}</span>
                        {typeof item.description === "string" ? (
                          item.description
                        ) : (
                          <ul>
                            {item.description.map((listItem) => (
                              <li>{listItem}</li>
                            ))}
                          </ul>
                        )}
                      </RightWrapperItem>
                    </>
                  ))}
                </>
              ) : null}
              {!!data.info[title].footer ? (
                <RightWrapperItem>
                  {data.info[title].footer}
                </RightWrapperItem>
              ) : null}
            </>
          ))}
        </RightWrapper>
      </BlogsWrapper>
    </BlogItemDetailContainer>
  );
};

export default DetailsPage;
