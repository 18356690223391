import React from "react";
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import {
  Wrapper,
  FooterSectionWrapper,
  FooterWrapper,
  FooterLogo,
  FooterItemList,
  FooterColHeader,
  FooterColList,
  DigitalInfo,
  Credits,
  SocialLinks,
} from "./style";
import { FOOTER_LIST } from "./constant";

const Footer = () => {
  return (
    <Wrapper>
      <FooterSectionWrapper>
        <FooterWrapper>
          <FooterLogo className="footer-logo">
            <img src="https://d29z5i6uc22g11.cloudfront.net/footer-logo.svg" alt="" />
          </FooterLogo>
          {FOOTER_LIST.map((item, index) => (
            <FooterItemList key={index}>
              <FooterColHeader>{item.title}</FooterColHeader>
              <FooterColList>
                <ul>
                  {item.items.map((list, index) => (
                    <li key={index} className="footer-item">
                      <a href={list.link}>{list.title}</a>
                    </li>
                  ))}
                </ul>
              </FooterColList>
            </FooterItemList>
          ))}
        </FooterWrapper>
        <DigitalInfo>
          <Credits>
            <div>© 2024 TechAbes. All rights reserved.</div>
            <div>
              <a href="">
                Privacy Policy
              </a>
            </div>
            <div>
              <a href="">
                Terms of service
              </a>
            </div>
            <div>
              <a href="">
                Cookie settings
              </a>
            </div>
          </Credits>
          <SocialLinks>
            {/* <a href="">
              <FacebookRoundedIcon fontSize="large" />
            </a>
            <a href="">
              <InstagramIcon fontSize="large" />
            </a> */}
            <a href="https://www.linkedin.com/company/techabescompany" target="_blank">
              <LinkedInIcon fontSize="large" />
            </a>
          </SocialLinks>
      </DigitalInfo>
      </FooterSectionWrapper>
    </Wrapper>
  );
};

export default Footer;
