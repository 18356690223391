import styled from "styled-components";

export const ApplicationInfoWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 50px;
  color: #fff;
  gap: 40px;
  @media only screen and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const LeftContainer = styled.div``;

export const ImageContainer = styled.div`
  @media only screen and (max-width: 768px) {
    height: 100%;
  }
  img {
    @media only screen and (max-width: 768px) {
      height: 100%;
      /* aspect-ratio: 5/2; */
      /* object-fit: cover; */
    }
  }
`;

export const RightContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(4, 1fr);
  gap: 20px;
  max-width: 90%;
  @media only screen and (max-width: 1024px) {
    max-width: 100%;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const ContentWrapper = styled.div``;

export const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  color: #fff;
  @media only screen and (max-width:  768px) {
    font-size: 16px;
  }
`;

export const SubTitle = styled.div`
  font-size: 16px;
  color: #fff;
  line-height: 1.5;
  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
`;
