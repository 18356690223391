export const TECHNOLOGIES_DATA = [
  {
    tech: "Mobile Technologies",
    info: "Our commitment to delivering top-tier mobile solutions relies on leveraging the latest technologies that shape the mobile app landscape. Explore the foundations and advancements that power our mobile development services.",
    description: [
      "Native Development",
      "Hybrid Development",
      "Iot Integration",
      "AR/VR Apps",
    ],
    imageUrl: "Mobile_Tech.jpg",
  },
  {
    tech: "Web Technologies",
    info: "Our web development services are anchored in the latest technologies that drive interactive and responsive web solutions. Explore the technologies that power our web development endeavors.",
    description: [
      "Frontend Development",
      "Backend Development",
      "Full stack Development",
      "Progressive Web Apps",
    ],
    imageUrl: "Web_Tech.jpg",
  },
  {
    tech: "Cloud and Devops",
    info: "Our approach to Cloud & DevOps technologies ensures robust, scalable, and agile solutions. Explore the technologies that power our cloud-based services and development operations.",
    description: [
      "Cloud Infrastructure",
      "Continuous Integration/Continuous Deployment (CI/CD)",
      "Containerization & Orchestration",
      "Monitoring & Logging",
    ],
    imageUrl: "Cloud_Devops.jpg",
  },
];
