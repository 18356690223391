import React from "react";
import { useParams } from "react-router";
import { TECHNOLOGIES_DETAILS } from "../../constants";
import {
  HeaderWrapper,
  LeftContainer,
  InforWrapper,
  TitleHeader,
  SubTitle,
  Text,
  RightContainer,
  ImageContainer,
} from "./style";

const TechHeader = () => {
  const { id } = useParams();
  const data = TECHNOLOGIES_DETAILS[id] ?? null;
  return (
    <HeaderWrapper>
      <LeftContainer>
        <TitleHeader>{data.titlePage.title}</TitleHeader>
        <InforWrapper>
          <SubTitle>{data.titlePage.subTitle}</SubTitle>
          <Text>{data.titlePage.text}</Text>
        </InforWrapper>
      </LeftContainer>
      <RightContainer>
        <ImageContainer>
          <img src={data.titlePage.image} alt="" width={"100%"} />
        </ImageContainer>
      </RightContainer>
    </HeaderWrapper>
  );
};

export default TechHeader;
