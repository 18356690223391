import styled from "styled-components";
import { Title, Description } from '../../../common-style';

export const HeaderWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  color: #fff;
  padding: 50px 0;
  gap: 40px;
  @media only screen and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    padding-top: 75px;
  }
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 50px;
  max-width: 75%;
  @media only screen and (max-width: 1024px) {
    gap: 30px;
    max-width: 100%;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const InforWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  @media only screen and (max-width: 768px) {
    gap: 5px;
  }
`;

export const TitleHeader = styled(Title)`
  color: #fc6600;
`;

export const SubTitle = styled.div`
   font-size: 28px;
  color: #fff;
  font-weight: 700;
  line-height: 1.3;
  @media only screen and (max-width: 1900px) {
    font-size: 24px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const Text = styled(Description)``;

export const RightContainer = styled.div``;

export const ImageContainer = styled.div`
  @media only screen and (max-width: 768px) {
    height: 100%;
  }
  img {
    @media only screen and (max-width: 768px) {
      height: 100%;
      /* aspect-ratio: 5/2; */
      /* object-fit: cover; */
    }
  }
`;
