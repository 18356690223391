import React from "react";
import { useParams } from "react-router";
import { TECHNOLOGIES_DETAILS } from "../../constants";
import {
  ServicesWrapper,
  ServiceInfoContainer,
  ServicesTitle,
  InfoWrapper,
  InfoContainer,
  InfoTitle,
  InfoSubTitle,
} from "./style";

const WhyUsSetion = () => {
  const { id } = useParams();
  const data = TECHNOLOGIES_DETAILS[id] ?? null;
  return (
    <ServicesWrapper>
      <ServiceInfoContainer>
        <ServicesTitle>{data.whyUs.title}</ServicesTitle>
      </ServiceInfoContainer>
      <InfoWrapper>
        {data.whyUs.info.map((service, index) => (
          <InfoContainer key={index}>
            <InfoTitle>{service.title}</InfoTitle>
            <InfoSubTitle>{service.text}</InfoSubTitle>
          </InfoContainer>
        ))}
      </InfoWrapper>
    </ServicesWrapper>
  );
};

export default WhyUsSetion;
