export const PORTFOLIO_DETIL = {
  "on-demand-service-app": {
    heading: {
      image: "/go_cabs_heading.png",
      title: "On-Demand Service App (Cab Booking App)",
      subTitle:
        "Transforming urban commutes with seamless, real-time cab bookings",
      info: [
        {
          title: "Industry",
          info: "Transportation",
        },
        {
          title: "Services",
          info: "Conceptualization, Design, Development, and Deployment",
        },
        {
          title: "Business Type",
          info: "Service",
        },
        {
          title: "Build your idea",
          info: "Consult our experts",
        },
      ],
    },
    aboutUs: {
      title: "GoCabs",
      info: "Our on-demand cab booking app revolutionizes the way users find and book rides. With seamless connectivity and user-friendly interfaces, we aim to provide a hassle-free travel experience. By leveraging the latest in GPS technology and secure payment gateways, we offer a reliable and efficient service that caters to the modern commuter's needs.",
    },
    inages: ["/go_cabs_description.svg"],
    infoPartOne: {
      title: "How Did We Shape The Idea Into A Plan",
      info: "We identified the growing need for reliable and efficient transportation options. Through extensive market research and user feedback, we developed a detailed plan to create a robust app that connects users with drivers effortlessly. We focused on user experience, integrating features such as real-time tracking, multiple payment options, and easy-to-use interfaces to ensure maximum user satisfaction.",
    },
    processInfo: {
      title: "Our Process",
      info: [
        {
          imgSrc: "/process-conceptualization.svg",
          title: "Conceptualization",
          items: [
            "Market understanding",
            "Competitive Analysis",
            "User Interview",
          ],
        },
        {
          imgSrc: "/Design.svg",
          title: "Design",
          items: ["User flow", "Journey Mapping", "Wireframing"],
        },
        {
          imgSrc: "/process-development.svg",
          title: "Development",
          items: ["Android, iOS Development", "User Testing"],
        },
        {
          imgSrc: "/Deployment.svg",
          title: "Deployment",
          items: [
            "App Store & Play Store Deployment",
            "Post-launch Maintenance",
          ],
        },
      ],
    },
    challenges: {
      title: "Our Project Challenges",
      list: [
        {
          title: "Real-time location tracking",
          info: "We integrated advanced GPS and mapping services to ensure accurate and real-time tracking of vehicles, providing users with precise ETAs and route information.",
        },
        {
          title: "Payment gateway integration",
          info: "Implemented secure and diverse payment options, including credit/debit cards, digital wallets, and cash, ensuring convenience and security for all users.",
        },
      ],
    },
    processDevelopment: {
      title: "Our Development Process",
      info: "Our development process followed an agile methodology, starting with detailed wireframes and progressing through iterative sprints. Each sprint included design, development, and testing phases, allowing us to refine features and address issues promptly. We conducted extensive user testing to ensure the app's functionality and usability met the highest standards.",
    },
  },
  "beauty-app": {
    heading: {
      image: "/glowify_heading.png",
      title: "Beauty App Named Glowify",
      subTitle: "Bringing personalized beauty services to your fingertips",
      info: [
        {
          title: "Industry",
          info: "Beauty",
        },
        {
          title: "Services",
          info: "Conceptualization, Design, <br /> Development, and Deployment",
        },
        {
          title: "Business Type",
          info: "Service",
        },
        {
          title: "Build your idea",
          info: "Consult our experts",
        },
      ],
    },
    aboutUs: {
      title: "Glowify",
      info: "Glowify is a beauty app designed to connect users with beauty professionals, offering a range of services from skincare consultations to makeup tutorials, all from the convenience of their smartphones. The app aims to provide personalized beauty solutions, leveraging advanced technologies like AR for virtual try-ons and AI for personalized recommendations.",
    },
    inages: ["/glowify_description.png"],
    infoPartOne: {
      title: "How Did We Shape The Idea Into A Plan",
      info: "We identified a gap in the market for on-demand beauty services and planned an app that provides personalized beauty solutions through expert consultations and virtual try-ons. Our approach included detailed market research and collaboration with beauty professionals to ensure the app met user needs and industry standards.",
    },
    processInfo: {
      title: "Our Process",
      info: [
        {
          imgSrc: "/process-conceptualization.svg",
          title: "Conceptualization",
          items: [
            "Market understanding",
            "Competitive Analysis",
            "User Interview",
          ],
        },
        {
          imgSrc: "/Design.svg",
          title: "Design",
          items: ["User flow", "Journey Mapping", "Wireframing"],
        },
        {
          imgSrc: "/process-development.svg",
          title: "Development",
          items: ["Android, iOS Development", "User Testing"],
        },
        {
          imgSrc: "/Deployment.svg",
          title: "Deployment",
          items: [
            "App Store & Play Store Deployment",
            "Post-launch Maintenance",
          ],
        },
      ],
    },
    challenges: {
      title: "Our Project Challenges",
      list: [
        {
          title: "Providing real-time beauty consultations",
          info: "Integrated high-quality video call functionalities and AI-driven virtual try-ons, allowing users to receive expert advice and try different looks from their own homes.",
        },
        {
          title: "User engagement",
          info: "Developed an engaging, interactive UI/UX that keeps users coming back. Features like personalized beauty tips, loyalty programs, and social sharing options were added to enhance user interaction.",
        },
      ],
    },
    processDevelopment: {
      title: "Our Development Process",
      info: "We utilized a mobile-first approach, focusing on user experience and incorporating feedback from beauty professionals and users throughout the development cycle. The development process included iterative testing and refinements to ensure the app's features were user-friendly and effective.",
    },
  },
  "social-media-app": {
    heading: {
      image: "/social_media_heading.png",
      title: "Social Media Networking Website",
      subTitle:
        "Connecting the world with secure, engaging social interactions",
      info: [
        {
          title: "Industry",
          info: "Social Networking",
        },
        {
          title: "Services",
          info: "Conceptualization, Design, <br /> Development, and Deployment",
        },
        {
          title: "Business Type",
          info: "Platform",
        },
        {
          title: "Build your idea",
          info: "Consult our experts",
        },
      ],
    },
    aboutUs: {
      title: "VConnect",
      info: "Our social media networking website connects users worldwide, fostering communication and community building through innovative features and a user-centric design. By offering a platform that prioritizes privacy, security, and engagement, we provide a safe and enjoyable space for users to connect, share, and interact.",
    },
    inages: ["/social_media_description.png"],
    infoPartOne: {
      title: "How Did We Shape The Idea Into A Plan",
      info: "We analyzed current social media trends and user needs to develop a comprehensive plan focused on user engagement, privacy, and feature-rich experiences. Our goal was to create a platform that stands out by offering unique features, such as customizable profiles, advanced privacy settings, and interactive content sharing.",
    },
    processInfo: {
      title: "Our Process",
      info: [
        {
          imgSrc: "/process-conceptualization.svg",
          title: "Conceptualization",
          items: [
            "Market understanding",
            "Competitive Analysis",
            "User Interview",
          ],
        },
        {
          imgSrc: "/Design.svg",
          title: "Design",
          items: ["User flow", "Journey Mapping", "Wireframing"],
        },
        {
          imgSrc: "/process-development.svg",
          title: "Development",
          items: ["Android, iOS Development", "User Testing"],
        },
        {
          imgSrc: "/Deployment.svg",
          title: "Deployment",
          items: [
            "App Store & Play Store Deployment",
            "Post-launch Maintenance",
          ],
        },
      ],
    },
    challenges: {
      title: "Our Project Challenges",
      list: [
        {
          title: "User privacy and data security",
          info: "Implemented robust encryption protocols and privacy controls, ensuring that user data is protected and that users have full control over their personal information and content.",
        },
        {
          title: "Scalability",
          info: "Designed a scalable architecture that can handle a growing number of users and interactions seamlessly, ensuring smooth performance even as the platform expands.",
        },
      ],
    },
    processDevelopment: {
      title: "Our Development Process",
      info: "Using a combination of modern web development frameworks and agile methodologies, we developed the platform in iterative stages. This approach allowed for continuous feedback and improvements, ensuring that the final product met user expectations and industry standards. We focused on creating an intuitive interface and integrating interactive features to enhance user engagement.",
    },
  },
  "crm-app": {
    heading: {
      image: "/crm_heading.png",
      title: "Content Management System (CMS)",
      subTitle: "Empowering effortless content creation and management",
      info: [
        {
          title: "Industry",
          info: "Software",
        },
        {
          title: "Services",
          info: "Conceptualization, Design, <br /> Development, and Deployment",
        },
        {
          title: "Business Type",
          info: "Platform",
        },
        {
          title: "Build your idea",
          info: "Consult our experts",
        },
      ],
    },
    aboutUs: {
      title: "CRM",
      info: "Our CMS provides a powerful and flexible solution for managing digital content, enabling businesses to create, edit, and publish content effortlessly. With intuitive controls and extensive customization options, our CMS caters to both technical and non-technical users, streamlining content management processes.",
    },
    inages: ["/glamify_description.png"],
    infoPartOne: {
      title: "How Did We Shape The Idea Into A Plan",
      info: "We recognized the need for a user-friendly CMS that caters to both technical and non-technical users. Our plan included developing a system with intuitive controls, extensive customization options, and seamless integration with various tools and plugins to meet diverse content management needs.",
    },
    processInfo: {
      title: "Our Process",
      info: [
        {
          imgSrc: "/process-conceptualization.svg",
          title: "Conceptualization",
          items: [
            "Market understanding",
            "Competitive Analysis",
            "User Interview",
          ],
        },
        {
          imgSrc: "/Design.svg",
          title: "Design",
          items: ["User flow", "Journey Mapping", "Wireframing"],
        },
        {
          imgSrc: "/process-development.svg",
          title: "Development",
          items: ["Android, iOS Development", "User Testing"],
        },
        {
          imgSrc: "/Deployment.svg",
          title: "Deployment",
          items: [
            "App Store & Play Store Deployment",
            "Post-launch Maintenance",
          ],
        },
      ],
    },
    challenges: {
      title: "Our Project Challenges",
      list: [
        {
          title: "Balancing flexibility with ease of use",
          info: "Designed an interface that is both intuitive for beginners and powerful for advanced users, providing extensive customization options without compromising usability.",
        },
        {
          title: "Integration with third-party tools",
          info: "Built a modular architecture that allows seamless integration with various tools and plugins, ensuring the CMS can adapt to different business requirements.",
        },
      ],
    },
    processDevelopment: {
      title: "Our Development Process",
      info: "Adopting an agile development methodology, we iteratively developed and tested features, ensuring the CMS met diverse user needs and supported various content types. The development process included extensive user testing and feedback loops to refine features and improve usability.",
    },
  },
  "cryptoloop-app": {
    heading: {
      image: "/cryptoloop_heading.png",
      title: "Stock Management Dashboard",
      subTitle:
        "Optimizing inventory control with real-time, actionable insights",
      info: [
        {
          title: "Industry",
          info: "Logistics",
        },
        {
          title: "Services",
          info: "Conceptualization, Design, <br /> Development, and Deployment",
        },
        {
          title: "Business Type",
          info: "Enterprise",
        },
        {
          title: "Build your idea",
          info: "Consult our experts",
        },
      ],
    },
    aboutUs: {
      title: "Cryptoloop",
      info: "Our stock management dashboard offers real-time inventory tracking, efficient stock control, and insightful analytics, empowering businesses to optimize their supply chain operations. The dashboard provides a comprehensive view of stock levels, movement, and trends, enabling businesses to make informed decisions.",
    },
    inages: ["/cryptoloop_description.png"],
    infoPartOne: {
      title: "How Did We Shape The Idea Into A Plan",
      info: "We identified the need for a comprehensive stock management solution and planned a dashboard that provides real-time visibility, alerts, and analytics to streamline inventory processes. Our approach included detailed consultations with logistics experts to understand industry challenges and requirements.",
    },
    processInfo: {
      title: "Our Process",
      info: [
        {
          imgSrc: "/process-conceptualization.svg",
          title: "Conceptualization",
          items: [
            "Market understanding",
            "Competitive Analysis",
            "User Interview",
          ],
        },
        {
          imgSrc: "/Design.svg",
          title: "Design",
          items: ["User flow", "Journey Mapping", "Wireframing"],
        },
        {
          imgSrc: "/process-development.svg",
          title: "Development",
          items: ["Android, iOS Development", "User Testing"],
        },
        {
          imgSrc: "/Deployment.svg",
          title: "Deployment",
          items: [
            "App Store & Play Store Deployment",
            "Post-launch Maintenance",
          ],
        },
      ],
    },
    challenges: {
      title: "Our Project Challenges",
      list: [
        {
          title: "Real-time data synchronization",
          info: "Implemented advanced APIs and real-time data processing to ensure up-to-date information across the dashboard, providing accurate and timely insights.",
        },
        {
          title: "User adoption",
          info: "Designed a user-friendly interface and provided thorough onboarding and support, ensuring users could quickly and effectively utilize the dashboard's features.",
        },
      ],
    },
    processDevelopment: {
      title: "Our Development Process",
      info: "We followed a user-centric development approach, collaborating closely with logistics experts and end-users to ensure the dashboard met practical needs and industry standards. The development process included iterative testing and refinements to optimize performance and usability.",
    },
  },
  "glamify-me-app": {
    heading: {
      image: "/e_commerece_heading.png",
      title: "E-commerce Website",
      subTitle:
        "Revolutionizing online shopping with a user-friendly, secure platform",
      info: [
        {
          title: "Industry",
          info: "Retail",
        },
        {
          title: "Services",
          info: "Conceptualization, Design, <br /> Development, and Deployment",
        },
        {
          title: "Business Type",
          info: "E-commerce",
        },
        {
          title: "Build your idea",
          info: "Consult our experts",
        },
      ],
    },
    aboutUs: {
      title: "Glamify",
      info: "Our e-commerce website offers a seamless shopping experience with a wide range of products, easy navigation, and secure transactions, designed to meet the demands of modern consumers. The platform focuses on providing a user-friendly interface, personalized recommendations, and efficient order management to enhance the overall shopping experience.",
    },
    inages: ["/e_com_description.png"],
    infoPartOne: {
      title: "How Did We Shape The Idea Into A Plan",
      info: "We conducted market research to understand consumer buying behaviors and preferences, crafting a plan to develop a user-friendly and feature-rich e-commerce platform. Our plan included features like advanced search filters, secure payment gateways, and personalized product recommendations to drive user engagement and sales.",
    },
    processInfo: {
      title: "Our Process",
      info: [
        {
          imgSrc: "/process-conceptualization.svg",
          title: "Conceptualization",
          items: [
            "Market understanding",
            "Competitive Analysis",
            "User Interview",
          ],
        },
        {
          imgSrc: "/Design.svg",
          title: "Design",
          items: ["User flow", "Journey Mapping", "Wireframing"],
        },
        {
          imgSrc: "/process-development.svg",
          title: "Development",
          items: ["Android, iOS Development", "User Testing"],
        },
        {
          imgSrc: "/Deployment.svg",
          title: "Deployment",
          items: [
            "App Store & Play Store Deployment",
            "Post-launch Maintenance",
          ],
        },
      ],
    },
    challenges: {
      title: "Our Project Challenges",
      list: [
        {
          title: "Ensuring smooth user experience during peak times",
          info: "Implemented robust backend solutions and scalable cloud infrastructure to handle high traffic volumes and ensure a smooth shopping experience.",
        },
        {
          title: "High cart abandonment rates",
          info: "Enhanced the checkout process by streamlining steps, adding multiple payment options, and implementing personalized reminders and incentives to encourage users to complete their purchases.",
        },
      ],
    },
    processDevelopment: {
      title: "Our Development Process",
      info: "The development process was agile, with continuous testing and optimization to ensure a flawless user experience. We focused on mobile responsiveness, secure payment integrations, and personalized user interfaces to meet diverse user needs and preferences.",
    },
  },
};
