import React from "react";
import { HeaderContentWrapper, HeaderItemMasterWrapper, HeaderItemWrapper, HeaderItem, HeaderTitle, HeaderSubTitle } from "./style";
import { HEADER_DATA } from "../constants";

const HeaderContentWeb = ({ handleUpdateHover }) => {
  const handleHoverItem = (value) => {
    handleUpdateHover(value);
  }
  return (
    <>
      <HeaderContentWrapper>
        <ul>
          {HEADER_DATA.map((item, index) => (
            <li index={item.id} onMouseOver={() => handleHoverItem(true)} onMouseLeave={() => handleHoverItem(false)} key={index}>
              <span className="header-item">{item.title}</span>
              <HeaderItemMasterWrapper className="selected-item">
                <HeaderItemWrapper className="selected-item">
                  <img src={item.image} alt="" />
                  <HeaderItem>
                    {item.hoverItems.map((data, index2) =>(
                      <div key={index2}>
                        <HeaderTitle href={data.link}>
                          {data.title}
                        </HeaderTitle>
                        <HeaderSubTitle>
                          {data.info}
                        </HeaderSubTitle>
                      </div>
                    ))}
                  </HeaderItem>
                </HeaderItemWrapper>
              </HeaderItemMasterWrapper>
            </li>
          ))}
        </ul>
      </HeaderContentWrapper>
      <HeaderContentWrapper shiftMargin>
        <ul style={{ alignItems: 'center' }}>
          <li>
            <a href="/contact-us" className="contact-us-button">
              Contact Us
            </a>
          </li>
        </ul>
      </HeaderContentWrapper>
    </>
  );
};

export default HeaderContentWeb;
