import React from "react";
import {
  TitleSectionWrapper,
  DescriptionWrapper,
  DescriptionTitle,
  DescriptionDescription,
} from "./style";

const Description = () => {
  return (
    <div>
      <TitleSectionWrapper>
        <DescriptionWrapper>
          <DescriptionTitle>Who We Are</DescriptionTitle>
          <DescriptionDescription>
            At <b>Techabes</b>, we’re a team of passionate innovators and problem solvers dedicated to crafting custom digital solutions that elevate businesses to the next level. Whether it's web and mobile app development or tailored industry solutions, we pride ourselves on delivering cutting-edge technology that meets the specific needs of our clients.
          </DescriptionDescription>
        </DescriptionWrapper>
        <DescriptionWrapper>
          <DescriptionTitle>What We Do Best</DescriptionTitle>
          <DescriptionDescription>
            At <b>Techabes</b>, we excel at building <b>bespoke digital platforms</b> designed to solve real-world challenges. Our expertise lies in <b>custom app development, web development</b>, and tailored solutions for sectors like <b>healthcare, e-commerce, and daycares</b>. From conceptualizing ideas to deploying robust systems, we focus on delivering solutions that don’t just work but inspire. Our unique ability to understand client needs and develop targeted solutions sets us apart as industry leaders.
          </DescriptionDescription>
        </DescriptionWrapper>
      </TitleSectionWrapper>
    </div>
  );
};

export default Description;
