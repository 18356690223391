import styled from "styled-components";

export const ServiceItemDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: space-between;
  /* max-width: 1700px;
  width: 100%;
  margin: 0 auto;
  padding: 80px 15px;
  @media only screen and (max-width: 1900px) {
    max-width: 1440px;
  }
  @media only screen and (max-width: 1550px) {
    max-width: 1340px;
  }
  @media only screen and (max-width: 1440px) {
    max-width: 1180px;
  }
  @media only screen and (max-width: 1280px) {
    margin: 0;
    max-width: calc(100% - 50px);
  } */
`;
