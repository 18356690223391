import React from "react";
import { CASE_STUDY_DATA } from "./constants";
import { TitleWrapper, Title, Description, InfoWrapper, MoreActionButton } from '../common-styles';
import {
  CaseStudiesSectionWrapper,
  CaseStudiesContainer,
  CaseStudyItem,
  CaseStudyImage,
} from "./style";

const CaseStudies = () => {
  return (
    <div style={{ backgroundColor: "#1b1b1b" }}>
      <CaseStudiesSectionWrapper>
        <TitleWrapper>
          <InfoWrapper>
            <Title>Our Portfolio</Title>
            <Description>
              "Driving Digital Success: Techabes Case Studies"
            </Description>
          </InfoWrapper>
          <MoreActionButton href={"/portfolio"}>
            View More Case Studies
          </MoreActionButton>
        </TitleWrapper>
        <CaseStudiesContainer>
          {CASE_STUDY_DATA.map((caseStudy) => (
            <CaseStudyItem key={caseStudy.title} href={caseStudy.link}>
              <CaseStudyImage src={caseStudy.img} alt="" />
            </CaseStudyItem>
          ))}
        </CaseStudiesContainer>
      </CaseStudiesSectionWrapper>
    </div>
  );
};

export default CaseStudies;
