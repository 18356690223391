import styled from "styled-components";
import { SectionWrapper } from '../common-styles';

export const Wrapper = styled.div`
  background: rgb(28,9,9);
  background: linear-gradient(90deg, rgba(28,9,9,1) 0%, rgba(233,116,16,1) 100%);
  display: flex;
`;

export const FooterSectionWrapper = styled(SectionWrapper)``;

export const FooterWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin: 0;
  gap: 40px;
  justify-content: space-between;
  border-bottom: 1px solid #fff;
  padding: 28px 48px;
  @media only screen and (max-width: 768px) {
    .footer-logo {
      grid-area: logo;
    }
    .footer-item {
      grid-area: item;
    }
    gap: 20px;
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas: 'logo logo logo' 'item item item';
  }
  @media only screen and (max-width: 550px) {
    padding: 10px;
    gap: 10px;
    box-sizing: border-box;
  }
`;

export const FooterLogo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  img {
    width: 200px;
    @media only screen and (max-width: 1024px) {
      width: 150px;
    }
  }
`;

export const FooterItemList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media only screen and (max-width: 768px) {
    padding: 10px;
    box-sizing: border-box;
  }
  @media only screen and (max-width: 420px) {
    max-width: 100px;
    padding: 5px;
  }
`;

export const FooterColHeader = styled.div`
  display: block;
  font-size: 26px;
  font-weight: 600;
  color: #fff;
  line-height: 36px;
  @media only screen and (max-width: 1550px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const FooterColList = styled.div`
  ul {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
    li {
      padding: 8px 0;
      position: relative;
      list-style-type: none;
      margin: 0;
      a {
        text-decoration: none;
        font-size: 18px;
        line-height: 1.3;
        color: #fff;
        position: relative;
        display: inline-block;
        font-weight: 300;
        @media only screen and (max-width: 1550px) {
          font-size: 14px;
        }
        &::after {
          content: "";
          position: absolute;
          width: 100%;
          transform: scaleX(0);
          height: 2px;
          bottom: 0;
          left: 0;
          background-color: #fff;
          transform-origin: bottom right;
          transition: transform 0.25s ease-out;
        }

        &:hover::after {
          transform: scaleX(1);
          transform-origin: bottom left;
        }
      }
    }
  }
`;

export const DigitalInfo = styled.div`
  display: flex;
  gap: 24px;
  justify-content: space-between;
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Credits = styled.div`
  gap: 24px;
  display: flex;
  flex-wrap: wrap;
  font-size: 18px;
  justify-content: center;
  @media only screen and (max-width: 1550px) {
    font-size: 14px;
  }
  a {
    text-decoration: none;
    color: #fff;
    border-bottom: 1px solid #fff;
  }
`;

export const SocialLinks = styled.div`
  font-size: 18px;
  @media only screen and (max-width: 1550px) {
    font-size: 14px;
  }
  a {
    margin-right: 12px;
    svg {
      color: #fff;
    }
  }
`;