import React, { useState } from "react";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {
  HeaderMobileContainer,
  HeaderList,
  HeaderItem,
  HeaderitemListWrapper,
  HeaderitemListTitle,
  TitleWrapper,
  HeaderItemWrapper,
} from "./style";
import { HEADER_DATA } from "../constants";

const HeaderMobileContent = () => {
  const [showListId, setShowListId] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const handleIsActive = (event) => {
    setIsActive(event.target.checked);
  };
  const handleExpandList = (id) => {
    if(showListId === 0 || showListId != id) {
      setShowListId(id);
    } else {
      setShowListId(0);
    }
  }
  return (
    <HeaderMobileContainer>
      <ul style={{ alignItems: 'center' }}>
        <li>
          <a href="/contact-us" className="contact-us-button">
            Contact Us
          </a>
        </li>
      </ul>
      <label for="check">
        <input type="checkbox" id="check" onChange={handleIsActive} />
        <span></span>
        <span></span>
        <span></span>
      </label>
      {isActive && (
        <HeaderList>
          {HEADER_DATA.map((header) => (
            <HeaderItemWrapper index={header.id}>
              <TitleWrapper onClick={() => handleExpandList(header.id)}>
                <HeaderItem>
                  {header.title}
                </HeaderItem>
                {showListId === header.id ? (
                  <RemoveIcon fontSize="large" />
                ): (
                  <AddIcon fontSize="large" />
                )}
              </TitleWrapper>
              {showListId === header.id && (
                <HeaderitemListWrapper>
                  {header.hoverItems.map((headerItem) => (
                    <>
                      <HeaderitemListTitle
                        className="title"
                        href={headerItem.link}
                      >
                        {headerItem.title}
                      </HeaderitemListTitle>
                    </>
                  ))}
                </HeaderitemListWrapper>
              )}
            </HeaderItemWrapper>
          ))}
        </HeaderList>
      )}
    </HeaderMobileContainer>
  );
};

export default HeaderMobileContent;
