import styled from "styled-components";
import { SectionWrapper } from '../common-styles';

export const CaseStudiesSectionWrapper = styled(SectionWrapper)``;

export const CaseStudiesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  column-gap: 40px;
  row-gap: 40px;
  width: 100%;
  @media only screen and (max-width: 768px) {
    grid-template-columns: repeat(2, auto);
  }
  @media only screen and (max-width: 500px) {
    grid-template-columns: repeat(1, auto);
  }
`;

export const CaseStudyItem = styled.a`
  width: 100%;
  padding: 0;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;
`;

export const CaseStudyImage = styled.img`
  max-width: 100%;
  text-align: center;
  height: auto;
  margin: 0 auto;
  vertical-align: middle;
  border-radius: 8px;
`;
