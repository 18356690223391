import styled from "styled-components";

export const PortfolioListDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1700px;
  width: 100%;
  margin: 0 auto;
  padding: 80px 15px;
  position: relative;
  justify-content: space-between;
  @media only screen and (max-width: 1900px) {
    max-width: 1440px;
  }
  @media only screen and (max-width: 1550px) {
    max-width: 1340px;
  }
  @media only screen and (max-width: 1440px) {
    max-width: 1180px;
  }
  @media only screen and (max-width: 1280px) {
    margin: 0;
    max-width: calc(100% - 50px);
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  margin: 15vh 0;
`;

export const TitleLeft2 = styled.div`
  color: #fff;
  text-align: center;
  margin-top: 20px;
  font-size: 75px;
  font-weight: 600;
  line-height: 1.1;
  position: relative;
  z-index: 2;
  @media only screen and (max-width: 1440px) {
    font-size: 60px;
  }
  @media only screen and (max-width: 1200px) {
    font-size: 48px;
  }
  @media only screen and (max-width: 1024px) {
    font-size: 40px;
    br {
      display: none;
    }
  }
  @media only screen and (max-width: 912px) {
    font-size: 36px;
  }
  @media only screen and (max-width: 767px) {
    font-size: 32px;
  }
  @media only screen and (max-width: 650px) {
    font-size: 28px;
    font-weight: 500;
    line-height: 26px;
  }
`;

export const TitleLeft3 = styled.div`
  font-size: 24px;
  text-align: center;
  @media only screen and (max-width: 1200px) {
    font-size: 20px;
  }
  @media only screen and (max-width: 767px) {
    font-size: 16px;
  }
  margin-top: 10px;
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  .item:nth-child(odd) {
    flex-direction: row;
    @media only screen and (max-width: 768px) {
      flex-direction: column;
    }
    .info {
      padding-left: 50px;
      @media only screen and (max-width: 768px) {
        padding-left: 0;
        margin-top: 20px;
      }
    }
  }
  .item:nth-child(even) {
    flex-direction: row-reverse;
    @media only screen and (max-width: 768px) {
      flex-direction: column-reverse;
    }
    .info {
      padding-right: 50px;
      @media only screen and (max-width: 768px) {
        padding-right: 0;
        margin-bottom: 20px;
      }
    }
  }
`;

export const Card = styled.div`
  display: flex;
  width: 100%;
  padding: 20px;
  margin-bottom: 60px;
  box-sizing: border-box;
  &:hover {
    border-radius: 8px;
    background-color: #141414;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  width: 50%;
  border-radius: 8px;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const CardImg = styled.img`
  border-radius: 8px;
  width: 100%;
  object-fit: cover;
`;

export const CardInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  width: 50%;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const CardTitle = styled.div`
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 24px;
  @media only screen and (max-width: 1280px) {
    font-size: 24px;
    margin-bottom: 10px;
  }
  @media only screen and (max-width: 1024px) {
    font-size: 20px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 16px;
  }
`;

export const CardSubtitle = styled.div`
  font-size: 16px;
  margin-bottom: 15px;
  @media only screen and (max-width: 1024px) {
    font-size: 14px;
  }
`;

export const TechContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const TechItem = styled.div`
  margin-right: 10px;
  display: flex;
`;

export const TechItemTitle = styled.div`
  font-size: 20px;
  line-height: 33px;
  font-weight: 900;
`;

export const TechItemDescription = styled.div`
  color: #fff;
  font-size: 16px;
  line-height: 16.5px;
`;

export const Seperator = styled.div`
  margin: 0 20px;
  height: 100%;
  border-left: 1px solid white;
`;

export const Button = styled.a`
  width: fit-content;
  margin-top: 40px;
  padding: 10px 20px;
  cursor: pointer;
  color: #fff;
  background: #fc6600;
  border: 0;
  border-radius: 4px;
  outline: none;
  text-decoration: none;
  transition: all 0.35s ease;
`;
