import React from "react";
import { NorthEast } from "@mui/icons-material";
import { BLOGS_LIST } from "./constants";
import { TitleWrapper, Title, Description, MoreActionButton, InfoWrapper } from '../common-styles';
import {
  BlogsSectionWrapper,
  BlogsContainer,
  BlogItem,
  BlogContentWrapper,
  BlogInfo,
  BlogTitle,
  BlogDate,
  BlogLink,
  BlogImage,
} from "./style";

const BlogsContainerComponent = ({
  showButton = true,
  isLandingPage = false,
}) => {
  return (
    <div style={{ backgroundColor: "#1b1b1b" }}>
      <BlogsSectionWrapper>
        <TitleWrapper>
          <InfoWrapper>
            <Title>Our Latest Articles</Title>
            <Description>
              "Dive into the World of Technology with our Blogs"
            </Description>
          </InfoWrapper>
          {showButton ? (
            <MoreActionButton href="/blogs">More Articles</MoreActionButton>
          ) : null}
        </TitleWrapper>
        <BlogsContainer>
          {BLOGS_LIST.slice(0, isLandingPage ? 3 : BLOGS_LIST.length).map(
            (blog) => (
              <BlogItem key={blog.title}>
                <BlogImage src={blog.image} alt="" />
                <BlogContentWrapper>
                  <BlogInfo>
                    <BlogDate>{blog.date}</BlogDate>
                    <BlogTitle>{blog.title}</BlogTitle>
                  </BlogInfo>
                  <BlogLink href={blog.link}>
                    Read More <NorthEast />
                  </BlogLink>
                </BlogContentWrapper>
              </BlogItem>
            )
          )}
        </BlogsContainer>
      </BlogsSectionWrapper>
    </div>
  );
};

export default BlogsContainerComponent;
