import React from "react";
import {
  BannerWrapper,
  ImageDataContainer,
  ImageDataContainerTitle,
  ImageSubText,
  ImageButton,
} from "./style";

const Banner = ({ data }) => {
  return (
    <BannerWrapper>
      <ImageDataContainer>
        <ImageDataContainerTitle
          dangerouslySetInnerHTML={{ __html: data.titleText }}
        />
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
          <ImageSubText dangerouslySetInnerHTML={{ __html: data.subText }} />
          <ImageButton href="/contact-us">{data.buttonText}</ImageButton>
        </div>
      </ImageDataContainer>
      <img src={data.image} alt="" className="section_banner" />
    </BannerWrapper>
  );
};

export default Banner;
