export const HEADER_DATA = [
  {
    id: 1,
    title: "Services",
    link: "",
    image: "https://d29z5i6uc22g11.cloudfront.net/services-menu.svg",
    hoverItems: [
      {
        title: "Mobile",
        link: "/services/mobile",
        info: "Unlock the potential of your ideas with our cutting-edge Mobile App Development services.",
      },
      {
        title: "Web",
        link: "/services/web",
        info: "Elevate your online presence with our comprehensive Web App Development services.",
      },
      {
        title: "Design",
        link: "/services/design",
        info: "Inspire your audience with visually stunning designs that captivate and communicate.",
      },
      {
        title: "Startup",
        link: "/services/start-up",
        info: "Ignite your startup journey with our Launch Boost services.",
      },
    ],
  },
  {
    id: 2,
    title: "Portfolio",
    link: "/portfolio",
    image: "https://d29z5i6uc22g11.cloudfront.net/proftfolio-menu.svg",
    hoverItems: [
      {
        title: "Go Cabs",
        link: "/portfolio/on-demand-service-app",
        info: "Unlock the potential of your ideas with our cutting-edge Mobile App Development services.",
      },
      {
        title: "Beauty App",
        link: "/portfolio/beauty-app",
        info: "Unlock the potential of your ideas with our cutting-edge Mobile App Development services.",
      },
      {
        title: "Cryptoloop",
        link: "/portfolio/cryptoloop-app",
        info: "Unlock the potential of your ideas with our cutting-edge Mobile App Development services.",
      },
      {
        title: "Glamify",
        link: "/portfolio/glamify-me-app",
        info: "Unlock the potential of your ideas with our cutting-edge Mobile App Development services.",
      },
      {
        title: "CRM App",
        link: "/portfolio/crm-app",
        info: "Unlock the potential of your ideas with our cutting-edge Mobile App Development services.",
      },
      {
        title: "Social Media App",
        link: "/portfolio/social-media-app",
        info: "Unlock the potential of your ideas with our cutting-edge Mobile App Development services.",
      },
    ],
  },
  {
    id: 3,
    title: "Technologies",
    link: "",
    image: "https://d29z5i6uc22g11.cloudfront.net/technology-menu.svg",
    hoverItems: [
      {
        title: "Mobile",
        link: "/technology/mobile",
        info: "Our commitment to delivering top-tier mobile solutions relies on leveraging the latest technologies that shape the mobile app landscape.",
      },
      {
        title: "Web",
        link: "/technology/web",
        info: "Our web development services are anchored in the latest technologies that drive interactive and responsive web solutions.",
      },
      {
        title: "Cloud",
        link: "/technology/cloud",
        info: "Our approach to Cloud & DevOps technologies ensures robust, scalable, and agile solutions.",
      },
    ],
  },
  {
    id: 4,
    title: "Blogs",
    link: "/blogs",
    image: "https://d29z5i6uc22g11.cloudfront.net/blogs-menu.svg",
    hoverItems: [
      {
        title: "Unveiling the Future",
        link: "/blogs/unveiling-the-future",
        info: "Unlock the potential of your ideas with our cutting-edge Mobile App Development services.",
      },
      {
        title: "Mastering Modern Web Design",
        link: "/blogs/mastering-modern-web-design",
        info: "Unlock the potential of your ideas with our cutting-edge Mobile App Development services.",
      },
      {
        title: "Unlocking the Power of IoT",
        link: "/blogs/unlocking-the-power-of-iot",
        info: "Unlock the potential of your ideas with our cutting-edge Mobile App Development services.",
      },
      {
        title: "The Future of Mobile App Development",
        link: "/blogs/the-future-of-mobile-app-development",
        info: "Unlock the potential of your ideas with our cutting-edge Mobile App Development services.",
      },
    ],
  },
];
