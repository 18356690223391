export const FOOTER_LIST = [
  {
    title: "Resources",
    items: [
      {
        title: "Blogs",
        link: "/blogs",
      },
      {
        title: "Portfolio",
        link: "/portfolio",
      },
      {
        title: "About Us",
        link: "/about-us",
      },
      {
        title: "Contact Us",
        link: "/contact-us",
      },
    ],
  },
  {
    title: "Services",
    items: [
      {
        title: "Mobile",
        link: "/services/mobile",
      },
      {
        title: "Web",
        link: "/services/web",
      },
      {
        title: "Design",
        link: "/services/design",
      },
      {
        title: "Start Up",
        link: "/services/start-up",
      },
    ],
  },
  {
    title: "Technology",
    items: [
      {
        title: "Mobile",
        link: "/technology/mobile",
      },
      {
        title: "Web",
        link: "/technology/web",
      },
      {
        title: "Cloud",
        link: "/technology/cloud",
      },
    ],
  },
];
