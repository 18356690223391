import styled from "styled-components";

export const SectionWrapper = styled.div`
  display: flex;
  max-width: 1700px;
  width: 100%;
  margin: 0 auto;
  color: #fff;
  flex-direction: column;
  padding: 50px 64px;
  gap: 32px;
  box-sizing: border-box;

  @media only screen and (max-width: 1900px) {
    max-width: 1440px;
  }
  @media only screen and (max-width: 1550px) {
    max-width: 1340px;
  }
  @media only screen and (max-width: 1440px) {
    max-width: 1280px;
  }
  @media only screen and (max-width: 1024px) {
    margin: 0;
    padding: 30px;
    max-width: 100%;
    box-sizing: border-box;
  }
  @media only screen and (max-width: 500px) {
    padding: 15px;
  }
  @media only screen and (max-width: 420px) {
    padding: 12px;
  }
`;

export const TitleWrapper =  styled.div`
    display: flex;
    justify-content: space-between;
    gap: 20px;
    padding-bottom: 50px;
    align-items: flex-end;
    @media only screen and (max-width: 1024px) {
        flex-direction: column;
        gap: 0;
        align-items: flex-start;
        padding-bottom: 30px;
    }
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Title = styled.div`
  font-size: 21px;
  font-weight: 700;
  color: #fc6600;
  line-height: 1.5;
  @media only screen and (max-width: 1550px) {
    font-size: 16px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
`;

export const Description = styled.div`
  font-size: 40px;
  color: ${({ isBlack }) => isBlack ? 'black' : 'white'};
  font-weight: 700;
  line-height: 1.2;
  @media only screen and (max-width: 1900px) {
    font-size: 32px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 20px;
    line-height: 24px;
  }
`;

export const MoreActionButton = styled.a`
    display: flex;
    justify-content: flex-end;
    text-decoration: none;
    list-style-type: none;
    color: #fff;
    border: 1px solid #fc6600;
    background-color: #fc6600;
    font-weight: 450;
    margin: 0;
    width: fit-content;
    align-items: center;
    font-size: 18px;
    cursor: pointer;
    transition: 1s;
    margin-top: 20px;
    padding: 7px 15px;
    border-radius: 50px;
    &:hover {
        color: #fc6600;
        box-shadow: inset 300px 0 0 0 #fff;
    }
  @media only screen and (max-width: 768px) {
   font-size: 12px;
  }
`;