import React from "react";
import {
  WorkLifeSectionWrapper,
  TitleWrapper,
  WorkLifeItemsContainer,
  WorkLifeItemsContainerItem,
  ItemWrapper,
  ImageWrapper,
} from "./style";

const work_life_items = [
  {
    title: "Learning \n & Development",
    image:
      "https://d29z5i6uc22g11.cloudfront.net/work-life/book.svg",
  },
  {
    title: "Mindful \n Personal Guidance",
    image:
      "https://d29z5i6uc22g11.cloudfront.net/work-life/discussiondiscussion.svg",
  },
  {
    title: "Constructive \n Feedback",
    image:
      "https://d29z5i6uc22g11.cloudfront.net/work-life/feedback.svg",
  },
  {
    title: "Inclusive \n & Open-Culture",
    image:
      "https://d29z5i6uc22g11.cloudfront.net/work-life/people.svg",
  },
  {
    title: "Innovation \n & Quality First",
    image:
      "https://d29z5i6uc22g11.cloudfront.net/work-life/mapping-idea.svg",
  },
  {
    title: "Proactive \n Implementation",
    image:
      "https://d29z5i6uc22g11.cloudfront.net/work-life/implementation.svg",
  },
  {
    title: "Best & Global \n  Opportunities",
    image:
      "https://d29z5i6uc22g11.cloudfront.net/work-life/global-money.svg",
  },
  {
    title: "Work with \n  Latest Technology",
    image:
      "https://d29z5i6uc22g11.cloudfront.net/work-life/cloudcloud.svg",
  },
];

const WorkLife = () => {
  const splitLine = (headData) => {
    if (typeof headData === "string") {
      let tempStr = headData.split("\n");
      return tempStr.map((item, i) => (
        <span key={`split-line-${i}`}>
          {item}
          {i < tempStr.length - 1 ? <br /> : null}
        </span>
      ));
    } else return headData;
  };
  return (
    <div style={{ backgroundColor: "#fff" }}>
      <WorkLifeSectionWrapper>
        <TitleWrapper>
          Work Life <br /> At Its <span>Best</span>
        </TitleWrapper>
        <WorkLifeItemsContainer>
          {work_life_items.map((item) => (
            <WorkLifeItemsContainerItem>
              <ItemWrapper>
                <ImageWrapper>
                  <img src={item.image} alt="work-life" />
                </ImageWrapper>
                <span className="data">{splitLine(item.title)}</span>
              </ItemWrapper>
            </WorkLifeItemsContainerItem>
          ))}
          <WorkLifeItemsContainerItem />
        </WorkLifeItemsContainer>
      </WorkLifeSectionWrapper>
    </div>
  );
};

export default WorkLife;
