import React from "react";
import {
  ImageWrapper,
  ImageDataContainer,
  ImageDataContainerTitle,
} from "./style";

const AboutUsBanner = () => {
  return (
    <ImageWrapper>
      <ImageDataContainer>
        <ImageDataContainerTitle>
          Transforming Businesses With Innovation
        </ImageDataContainerTitle>
      </ImageDataContainer>
      <img src="AR_VR.jpg" alt="" width={'100%'} />
    </ImageWrapper>
  );
};

export default AboutUsBanner;
