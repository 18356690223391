import styled from "styled-components";
import { SectionWrapper } from "../common-styles";

export const VideoWrapper = styled.div`
  /* width: 100%;
  height: 100%; */
  margin-top: 40px;
  position: relative;
  @media only screen and (max-width: 767px) {
    padding-top: 80px;
  }
  .intro_video {
    width: 100% !important;
    height: 100% !important; 
    object-fit: cover;
    @media only screen and (min-width: 767px) and (max-width: 1024px) {
      height: 400px;
    }
    @media only screen and (max-width: 767px) {
      position: relative;
      height: 100%;
      width: calc(100% - 30px);
      z-index: 1;
      /* margin: 40px 15px 20px; */
    }
  }
`;

export const VideoDataContainer = styled(SectionWrapper)`
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  color: #fff;
  z-index: 2;
  gap: 0;
  .consult-us-button {
    display: flex;
    align-items: center;
    width: fit-content;
    text-decoration: none;
    list-style-type: none;
    color: #fff;
    border: 1px solid #fc6600;
    background-color: #fc6600;
    font-weight: 600;
    padding: 12px 35px;
    border-radius: 30px;
    font-size: 18px;
    transition: 1s;
    @media only screen and (max-width: 768px) {
      font-size: 12px;
    }
    &:hover {
      color: #fc6600;
      box-shadow: inset 300px 0 0 0 #fff;
    }
  }
  @media only screen and (max-width: 767px) {
    position: relative;
    transform: none;
    left: auto;
    top: auto;
  }
`;

export const VideoDataContainerTitle = styled.div`
  font-size: 60px;
  font-weight: 500;
  @media only screen and (max-width: 1900px) {
    font-size: 48px;
  }
  @media only screen and (max-width: 1550px) {
    font-size: 42px;
  }
  @media only screen and (min-width: 767px) and (max-width: 1024px) {
    font-size: 32px;
  }
  @media only screen and (max-width: 767px) {
    font-size: 28px;
  }
`;

export const VideoDataContainerDescription = styled.div`
  display: block;
  font-size: 24px;
  font-weight: 300;
  line-height: 1.4;
  margin-top: 15px;
  margin-bottom: 25px;
  @media only screen and (max-width: 1680px) {
    font-size: 20px;
  }
  @media only screen and (max-width: 1550px) {
    font-size: 16px;
  }
`;
