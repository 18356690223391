import React from "react";
import { InfoWrapper, Title, Description } from '../common-styles';
import {
  AboutUsSectionWrapper,
  AboutUsLeftContainer,
  AboutUsItemWrapper,
  AboutUsItemCardDataWrapper,
  AboutUsItemCardValue,
  AboutUsItemCard,
  AboutUsItemCardTitle,
  AboutUsItemCardValueSmall,
  AboutUsDescripitonWrapper,
  DescriptionTitle,
  DescriptionLabel,
  ImageContainer,
  ImageItem,
} from "./style";

const AboutUs = () => {
  return (
    <AboutUsSectionWrapper>
      <AboutUsLeftContainer>
        <InfoWrapper>
          <Title>About</Title>
          <Description>TechAbes</Description>
        </InfoWrapper>
        <AboutUsItemWrapper>
          <AboutUsItemCardDataWrapper>
            <AboutUsItemCard />{" "}
            <AboutUsItemCardTitle> Worked With </AboutUsItemCardTitle>
            <AboutUsItemCardValue>50+</AboutUsItemCardValue>
            <AboutUsItemCardValueSmall>Clients</AboutUsItemCardValueSmall>
          </AboutUsItemCardDataWrapper>
          <AboutUsItemCardDataWrapper>
            <AboutUsItemCard />{" "}
            <AboutUsItemCardTitle> Fulfiled </AboutUsItemCardTitle>
            <AboutUsItemCardValue>70+</AboutUsItemCardValue>
            <AboutUsItemCardValueSmall>Projects</AboutUsItemCardValueSmall>
          </AboutUsItemCardDataWrapper>
          <AboutUsItemCardDataWrapper>
            <AboutUsItemCard />{" "}
            <AboutUsItemCardTitle> Developers </AboutUsItemCardTitle>
            <AboutUsItemCardValue>50+</AboutUsItemCardValue>
          </AboutUsItemCardDataWrapper>
        </AboutUsItemWrapper>
      </AboutUsLeftContainer>
      <AboutUsDescripitonWrapper>
        <DescriptionTitle>Navigating Technology Frontiers</DescriptionTitle>
        <DescriptionLabel>
          Explore the boundless possibilities of technology with us. We are a
          dynamic team of IT experts dedicated to delivering innovative
          solutions that propel your business forward. Hover over to discover
          more about our journey and commitment to excellence.
        </DescriptionLabel>
        <ImageContainer>
          <ImageItem src="https://d29z5i6uc22g11.cloudfront.net/about_us.svg" alt="" />
        </ImageContainer>
      </AboutUsDescripitonWrapper>
    </AboutUsSectionWrapper>
  );
};

export default AboutUs;
