import styled from "styled-components";
import { SectionWrapper, Title, Description } from "../common-style";

export const ContactUsDetailContainer = styled(SectionWrapper)`
  padding-top: 100px;
`;

export const ContactUsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 30px 0;
  gap: 20px;
  align-items: center;
`;

export const LeftTtile = styled(Title)``;

export const SubTitle = styled(Description)``;

export const RightWrapperContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 10px;
  padding: 30px;
`;

export const RightWrapper = styled.div`
  display: grid;
  grid-template-columns: 6fr 4fr;
  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 20px;
    gap: 20px;
  }
  column-gap: 50px;
`;

export const RightTitle = styled(Title)`
  color: #000;
  font-weight: 500;
`;