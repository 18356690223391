import styled from "styled-components";
import { SectionWrapper, Title, Description } from '../../common-style';

export const PortfolioItemDetailContainer = styled(SectionWrapper)`
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: space-between;
  padding-top: 100px;
`;

export const HeaderContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
  @media only screen and (max-width: 1024px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
  }
`;

export const HeaderLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 50px;
  @media only screen and (max-width: 1024px) {
    gap: 20px;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  @media only screen and (max-width: 1024px) {
    gap: 10px;
  }
`;

export const ItemTitle = styled(Title)``;

export const ItemSubTitle = styled.div`
  font-size: 24px;
  color: #fff;
  line-height: 1.5;
  @media only screen and (max-width: 1900px) {
    font-size: 18px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

export const InfoContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  row-gap: 50px;
  @media only screen and (max-width: 1024px) {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 10px;
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const InfoWrapperTitle = styled(Description)`
  color: #fc6600;
  font-weight: 700;
`;

export const InfoWrapperSubtitle = styled(Description)`
`;

export const HeaderRight = styled.div``;

export const TextContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  @media only screen and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const TextTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  @media only screen and (max-width: 768px) {
    gap: 15px;
  }
`;

export const TextContainerTitle = styled(Title)``;

export const TextContainerInfo = styled(Description)`
  color: ${({ color }) => color ?? "#fff"};
`;

export const ImagesContainer = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 0;
  figure {
    width: 100%;
    margin: 0;
    position: relative;
    &:nth-child(even) {
      margin-top: 70px;
    }
    img {
      width: 100%;
      backface-visibility: hidden;
      /* @media only screen and (max-width: 1600px) {
        transform: translate(0, -20px) scale(0.8);
      } */
    }
  }
`;

export const IdeaInfoContainer = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  background-color: #fff;
  border-radius: ${({ hasBorderRadius }) => hasBorderRadius ? `10px` : '0'}
`;

export const IdeaInfoTitle = styled.div`
  display: flex;
  font-size: 24px;
  line-height: 1.2;
  font-weight: 500;
  color: #000;
  @media only screen and (max-width: 768px) {
    font-size: 14px;
    font-weight: 600;
  }
`;


export const ChallengeContainer = styled.div`
  display: grid;
  padding: 30px;
  grid-template-columns: 40% 60%;
  @media only screen and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 15px;
  }
`;

export const ChallengeItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const ChallengeItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ChallengeNumber = styled.div`
  color: #fc6600;
  display: flex;
  gap: 8px;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.3;
`;

export const ChallengeDivider = styled.div`
  display: inline-block;
  margin-right: 15px;
        width: 50px;
        height: 3px;
        font-weight: 500;
        background: linear-gradient(90deg, rgba(233, 116, 16, 1), rgba(233, 116, 16, 1));
`;

export const ChallengeContent = styled.div`
  display: flex;
  align-items: center;
`;

export const ChallengeTitle = styled(IdeaInfoTitle)`
  color: #fc6600;
`;

export const ChallengeInfo = styled(Description)`
  color: #000;
`;
