import React from "react";
import Banner from "./sections/banner";
import Client from "./sections/clients";
import ServiceDesctipition from "./sections/service-description";
import ServiceInfo from "./sections/services-info";
import TechnologiesSection from "./sections/technologies";
import { ServiceItemDetailContainer } from "./style";

const ServicesItemDetail = ({ data }) => {
  return (
    <ServiceItemDetailContainer>
      <Banner data={data.landingImage} />
      {/* <Client /> */}
      <ServiceDesctipition />
      <ServiceInfo />
      <TechnologiesSection />
    </ServiceItemDetailContainer>
  );
};

export default ServicesItemDetail;
