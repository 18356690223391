import styled from "styled-components";
import { SectionWrapper } from '../common-styles';

export const TechnologiesSectionWrapper = styled(SectionWrapper)`
  position: relative;
  color: #fff;
`;


export const TechContainer = styled.div`
  block-size: 100%;
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 5px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #fc6600;
    border-radius: 5px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #fff;
  }
  height: 600px;
  @media only screen and (max-width: 1900px) {
    height: 502px;
  }
  @media only screen and (max-width: 1550px) {
    height: 470px;
  }
  @media only screen and (max-width: 1440px) {
    height: 420px;
  }
  @media only screen and (max-width: 1240px) {
    height: 400px;
  }
  @media only screen and (max-width: 1180px) {
    height: 380px;
  }
  @media only screen and (max-width: 1100px) {
    height: 360px;
  }
  @media only screen and (max-width: 1024px) {
    height: 800px;
  }
  @media only screen and (max-width: 768px) {
    height: 700px;
  }
  @media only screen and (max-width: 550px) {
    height: 550px;
  }
  @media only screen and (max-width: 430px) {
    height: 580px;
  }
`;

export const TechContainerItem = styled.div`
  block-size: 100%;
  scroll-snap-align: start;
  scroll-snap-stop: always;
  padding: 0 20px 0 0;

  display: grid;
  grid-template-columns: 2fr 3fr;
  gap: 20px;
  @media only screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    gap: 0;
    justify-content: space-between;
  }
`;

export const TechContainerLeftItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
`;

export const TechContainerLeftItemTitle = styled.div`
  color: #fff;
  font-size: 28px;
  font-weight: 600;
  line-height: 1.3;
  @media only screen and (max-width: 1280px) {
    font-size: 24px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 20px;
    margin-top: 20px;
  }
`;

export const TechContainerLeftInfoDescription = styled.div`
  font-size: 16px;
  color: #fff;
  line-height: 24px;
  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
`;

export const TechContainerLeftItemDescription = styled.ul`
  padding: 0 0 0 20px;
  li {
    font-size: 16px;
    color: #fff;
    line-height: 24px;
    @media only screen and (max-width: 768px) {
      font-size: 12px;
    }
  }
`;

export const TechContainerRightItem = styled.div`
  border-radius: 10px;
`;

export const TechTechnologyImage = styled.img`
  border-radius: 10px;
  width: 100%;
  height: 600px;
  @media only screen and (max-width: 1900px) {
    max-height: 502px;
  }
  @media only screen and (max-width: 1550px) {
    max-height: 470px;
  }
  @media only screen and (max-width: 1440px) {
    max-height: 420px;
  }
  @media only screen and (max-width: 1240px) {
    max-height: 400px;
  }
  @media only screen and (max-width: 1180px) {
    max-height: 380px;
  }
  @media only screen and (max-width: 1100px) {
    max-height: 360px;
  }
  @media only screen and (max-width: 1024px) {
    max-height: 550px;
  }
  @media only screen and (max-width: 850px) {
    max-height: 520px;
  }
  @media only screen and (max-width: 768px) {
    max-height: 400px;
  }
  @media only screen and (max-width: 550px) {
    max-height: 250px;
  }
  @media only screen and (max-width: 410px) {
    max-height: 220px;
  }
`;
