export const SERVICES_DETAILS = [
  {
    title: "Mobile Solutions",
    description:
      "Unlock the potential of your ideas with our cutting-edge Mobile App Development services. Whether you envision a native iOS app, an Android masterpiece, or a cross-platform solution, we bring your concepts to life.",
    link: "/services/mobile",
    image: "mobile_service.png",
    items: [
      {
        title: "Native Apps",
      },
      {
        title: "Hybrid Apps",
      },
      {
        title: "AR/VR Apps",
      },
      {
        title: "TV apps",
      },
    ],
  },
  {
    title: "Web Solutions",
    link: "/services/web",
    description:
      "Elevate your online presence with our comprehensive Web App Development services. From sleek and interactive websites to powerful web applications, we design and develop solutions tailored to your unique needs.",
    image: "Web_Solutions_service.png",
    items: [
      {
        title: "Custom Website",
      },
      {
        title: "Custom CRM",
      },
      {
        title: "E-commerce Website",
      },
      {
        title: "SaaS website",
      },
    ],
  },
  {
    title: "Design Solutions",
    link: "/services/design",
    description:
      "Inspire your audience with visually stunning designs that captivate and communicate. Our Design Services encompass a spectrum of creativity, from user-centric interfaces to eye-catching branding and graphic solutions.",
    image: "UI_UX_service.png",
    items: [
      {
        title: "SaaS Product Design",
      },
      {
        title: "Web Design",
      },
      {
        title: "Mobile App Design",
      },
      {
        title: "Brand Design",
      },
    ],
  },
  {
    title: "Startup Solutions",
    link: "/services/start-up",
    description:
      "Ignite your startup journey with our Launch Boost services. We specialize in providing strategic support to startups, offering everything from ideation workshops and MVP development to market entry strategies. Propel your vision into the market with our tailored solutions designed to accelerate your startup's growth trajectory.",
    image: "startup_service.png",
    items: [
      {
        title: "Idea Consulting",
      },
      {
        title: "MVP Development",
      },
      {
        title: "Prototyping",
      },
      {
        title: "Product Development",
      },
    ],
  },
];
