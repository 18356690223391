import React from "react";
import {
  NumberSectionWrapper,
  Container,
  ItemWrapper,
  NumberWrapper,
  NumberTitle,
  DataDetail,
} from "./style";

const NumbersSection = () => {
  return (
    <div>
      <NumberSectionWrapper>
        <Container>
          <ItemWrapper>
            <NumberWrapper>
              <NumberTitle>100+</NumberTitle>
              <DataDetail>Project Delivered</DataDetail>
            </NumberWrapper>
            <NumberWrapper>
              <NumberTitle>50+</NumberTitle>
              <DataDetail>Tech experts On-board</DataDetail>
            </NumberWrapper>
            <NumberWrapper>
              <NumberTitle>10+</NumberTitle>
              <DataDetail>Investment Raised for Startups</DataDetail>
            </NumberWrapper>
          </ItemWrapper>
        </Container>
      </NumberSectionWrapper>
    </div>
  );
};

export default NumbersSection;
