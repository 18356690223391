import React, { useState } from "react";
import { TitleWrapper, Title, Description, InfoWrapper } from '../common-styles';
import {
  GradientWrapper,
  CertificationSectionWrapper,
  Cards,
  Card,
  TechDescription,
} from "./style";

const cardList = [
  {
    imageUrl: "AR_VR.jpg",
    content:
      "Dive into the world of augmented and virtual reality with Techabes. From interactive experiences to training simulations, we unlock the full potential of AR/VR for businesses.",
  },
  {
    imageUrl: "Ecom.jpg",
    content:
      "Transform e-commerce with Techabes. Our tailored solutions enhance user experiences, optimize conversions, and drive growth for online retailers in a competitive marketplace.",
  },
  {
    imageUrl: "Gaming.jpg",
    content:
      "Elevate gaming experiences with Techabes. Our immersive solutions push the boundaries of gameplay, driving user engagement and retention to new heights.",
  },
  {
    imageUrl: "Healthcare.jpg",
    content:
      "Empower healthcare with Techabes. Our solutions streamline processes, improve patient outcomes, and revolutionize the delivery of care through innovative digital technologies",
  },
  {
    imageUrl: "Real_estate.jpg",
    content:
      "Redefine real estate with Techabes. From virtual property tours to streamlined transactions, our solutions revolutionize the way properties are showcased, marketed, and sold.",
  },
];

const Certification = () => {
  const [selectedCard, setSelectedCard] = useState(0);
  return (
    <GradientWrapper>
      <CertificationSectionWrapper>
        <TitleWrapper>
          <InfoWrapper>
            <Title>Industries Mastered</Title>
            <Description isBlack>
              "Transforming Industries with Techabes: Where Innovation Meets Impact"
            </Description>
          </InfoWrapper>
        </TitleWrapper>
        <Cards>
          {cardList.map((card, index) => (
            <Card
              key={index}
              url={card.imageUrl}
              isActive={selectedCard === index}
              onMouseOver={() => setSelectedCard(index)}
            >
              <div className="image-wrapper">
                <img src={card.imageUrl} alt="" width={"100%"} height={"100%"} />
              </div>
              <TechDescription selectedCard={selectedCard === index}>
                {card.content}
              </TechDescription>
            </Card>
          ))}
        </Cards>
      </CertificationSectionWrapper>
    </GradientWrapper>
  );
};

export default Certification;
