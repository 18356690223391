import styled from "styled-components";
import { Title, Description } from '../../../common-style';

export const ServicesWrapper = styled.div`
  display: flex;
  color: #fff;
  flex-direction: column;
  gap: 30px;
`;

export const ServiceInfoContainer = styled.div`
  gap: 10px;
  display: flex;
  flex-direction: column;
`;

export const ServicesTitle = styled(Title)``;

export const SubText = styled(Description)``;

export const InfoWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 35px;
  @media only screen and (max-width:  768px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  gap: 10px;
  padding: 50px 32px;
  &:hover {
    background-color: #fc6600;
    cursor: pointer;
    .title {
      color: #fff !important;
    }
  }
`;

export const InfoTitle = styled.div`
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  color: #000;
  text-align: center;
  @media only screen and (max-width:  768px) {
    font-size: 16px;
  }
`;

export const InfoSubTitle = styled.div`
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  color: #000;
  text-align: center;
  @media only screen and (max-width:  768px) {
    font-size: 12px;
  }
`;
