import React from "react";
import { useParams } from "react-router";
import Header from "../../landing-page/header";
import TechDetails from "./details";
import Footer from "../../landing-page/footer";
import { TECHNOLOGIES_DETAILS } from "./constants";

const TechnologyDetails = () => {
  const { id } = useParams();
  const data = TECHNOLOGIES_DETAILS[id] ?? null;
  if (!data) {
    return (
      <div style={{ backgroundColor: "#1b1b1b"}}>
        <Header backgroundColor={"#1b1b1b"} />
        <Footer />
      </div>
    );
  }
  return (
    <div style={{ backgroundColor: "#1b1b1b"}}>
      <Header backgroundColor={"#1b1b1b"} />
      <TechDetails />
      <Footer />
    </div>
  );
};

export default TechnologyDetails;
