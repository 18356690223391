import styled from "styled-components";
import { SectionWrapper } from '../common-styles';

export const HeaderWrapper = styled.div`
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : "#000"};
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 99;
  padding: 15px 0 15px 0;
`;

export const HeaderContainer = styled(SectionWrapper)`
  opacity: 0.9;
  padding-top: 0px;
  padding-bottom: 0px;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  @media only screen and (max-width: 1280px) {
    margin: 0;
  }
`;

export const LogoWrapper = styled.div`
  margin-left: 0;
  cursor: pointer;
  margin-top: 10px;
  @media only screen and (max-width: 768px) {
    width: 120px;
    img {
      width: 100%;
    }
  }
`;
