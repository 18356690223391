import React, { useState, useRef } from "react";
import { useParams } from "react-router";
import { SERVICES_DESCRIPTIONS } from "../../constants";
import {
  DescriptionWrapper,
  DescriptionTitle,
  DescriptionSubTitle,
  ServicesContainer,
  ServicesLeftContainer,
  ServicesRightContainer,
  ServicesLeftInnerContainer,
  ImageWrapper,
  AccordianBody,
  AccordianHeader,
} from "./style";

const ServiceDesctipition = () => {
  const currentRef = useRef();
  const { id } = useParams();
  const data = SERVICES_DESCRIPTIONS[id] ?? null;
  const [indexId, setIndex] = useState(0);
  const handleClick = (index) => {
    if (window.innerWidth <= 900) {
      currentRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    setIndex(index);
  }
  return (
    <DescriptionWrapper>
      <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <DescriptionTitle dangerouslySetInnerHTML={{ __html: data.title }} />
        <DescriptionSubTitle
          dangerouslySetInnerHTML={{ __html: data.subTitle }}
        />
      </div>
      <ServicesContainer ref={currentRef}>
        <ServicesLeftContainer>
          <ServicesLeftInnerContainer>
            {data.features.map((item, index) => (
              <div
                className={`${indexId === index ? "current" : "tab-content"}`}
              >
                <AccordianHeader onClick={() => setIndex(index)}>
                  {item.imageSrc ? <ImageWrapper><img src={item.imageSrc} alt="" /></ImageWrapper> : null}
                  <div
                    className={`serv_card_head`}
                  >
                    {item.title}
                  </div>
                </AccordianHeader>
                <AccordianBody>
                  {item.description}
                </AccordianBody>
              </div>
            ))}
          </ServicesLeftInnerContainer>
        </ServicesLeftContainer>

        <ServicesRightContainer>
          <ul>
            {data.features.map((item, index) => (
              <li
                className={`tab-link ${indexId === index ? "current" : ""}`}
                onClick={() => handleClick(index)}
              >
                <span>
                  <span class="scroll_list_num" />
                  {item.title}
                </span>
              </li>
            ))}
          </ul>
        </ServicesRightContainer>
      </ServicesContainer>
    </DescriptionWrapper>
  );
};

export default ServiceDesctipition;
