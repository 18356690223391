import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Mail from "@mui/icons-material/Mail";
import Call from "@mui/icons-material/Call";
import emailjs from '@emailjs/browser';
import * as Yup from "yup";
import {
  FormContainer,
  InputContainer,
  IconContainer,
  SubmitButtonWrapper,
  SubmitButton,
  FieldContainer,
} from "./form-style";

const contactUsSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string().required("Email is required"),
  phone: Yup.string().required("Phone is required"),
  budget: Yup.string().required("Budget is required"),
  requirement: Yup.string().required("Requirement is required"),
});

const FormComponentDetail = () => {
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("success");

  const handleClose = () => {
    setOpen(false);
  }
  return (
    <div>
      <Formik
        initialValues={{
          name: "",
          email: "",
          phone: "",
          budget: "",
          currency: "$",
          requirement: "",
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          const templateParams = {
            name: values.name,
            email: values.email,
            phone: values.phone,
            budget: `${values.currency}${values.budget}`,
            requirement: values.requirement,
          }
          emailjs.send('service_sghwtv8', 'template_pieqf92', templateParams, { publicKey: 'XnBkgdABKYXG3O1um'}).then(
            function (response) {
              setSeverity("success");
              setOpen(true);
              setSubmitting(false);
              resetForm({ 
                name: "",
                email: "",
                phone: "",
                budget: "",
                requirement: "",
              });
            },
            function (err) {
              setSubmitting(false);
              setOpen(true);
              setSeverity("error");
            },
          );
        }}
        validationSchema={contactUsSchema}
      >
        {({ isSubmitting }) => (
          <Form>
            <FormContainer>
              <FieldContainer>
                <InputContainer>
                  <label htmlFor="name">Full Name</label>
                  <Field type="text" name="name" placeholder="" />
                  <ErrorMessage name="name" component="div" className="error" />
                </InputContainer>
                <InputContainer>
                  <label htmlFor="email">Email</label>
                  <IconContainer>
                    <Mail />
                    <Field type="email" name="email" placeholder="" className="icon-added" />
                  </IconContainer>
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error"
                  />
                </InputContainer>
              </FieldContainer>
              <FieldContainer>
                <InputContainer>
                  <label htmlFor="budget">Budget</label>
                  <IconContainer>
                    <Field as="select" name="currency">
                      <option value="$">$</option>
                      <option value="£">£</option>
                      <option value="€">€</option>
                      <option value="₹">₹</option>
                    </Field>
                    <Field type="number" name="budget" placeholder="" />
                  </IconContainer>
                  <ErrorMessage
                    name="budget"
                    component="div"
                    className="error"
                  />
                </InputContainer>

                <InputContainer>
                  <label htmlFor="phone">Phone</label>
                  <IconContainer>
                    <Call />
                    <Field type="number" name="phone" placeholder="" className="icon-added" />
                  </IconContainer>
                  <ErrorMessage
                    name="phone"
                    component="div"
                    className="error"
                  />
                </InputContainer>
              </FieldContainer>
              <InputContainer>
                <label htmlFor="requirement">Message</label>
                <Field
                  rows="10"
                  as="textarea"
                  type="text"
                  name="requirement"
                  placeholder="Type your message..."
                />
                <ErrorMessage
                  name="requirement"
                  component="div"
                  className="error"
                />
              </InputContainer>
              <SubmitButtonWrapper>
                <SubmitButton type="submit" disabled={isSubmitting}>
                  Submit
                </SubmitButton>
              </SubmitButtonWrapper>
            </FormContainer>
          </Form>
        )}
      </Formik>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        className="info"
      >
        <Alert
          onClose={handleClose}
          severity={severity}
          variant="filled"
        >
          {severity === "success" ? "Thank you for your response. Our team will get back to you soon!" : "Something went wrong. Please try again."}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default FormComponentDetail;
