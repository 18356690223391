import React from "react";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import AboutUs from "./details-pages/about-us";
import BlogsList from "./details-pages/blogs/blogs-list";
import BlogsDetails from "./details-pages/blogs/blogs-details";
import ServiceDetails from "./details-pages/services";
import TechnologyDetails from "./details-pages/technologies";
import PortfolioList from "./details-pages/portfolio/portfolio-list";
import PortfolioDetail from "./details-pages/portfolio/portfolio-details";
import ContactUs from "./details-pages/contact-us";
import LandingPage from "./landing-page";
import { AppWrapper } from "./styles";

// add routes here later while adding other pages
const App = () => {
  return (
    <AppWrapper>
      <BrowserRouter>
        <Routes>
          <Route exact path="/about-us" Component={AboutUs} />
          <Route exact path="/portfolio" Component={PortfolioList} />
          <Route exact path="/portfolio/:id" Component={PortfolioDetail} />
          <Route exact path="/blogs" Component={BlogsList} />
          <Route exact path="/blogs/:id" Component={BlogsDetails} />
          <Route exact path="/services/:id" Component={ServiceDetails} />
          <Route exact path="/technology/:id" Component={TechnologyDetails} />
          <Route exact path="/contact-us" Component={ContactUs} />
          <Route exact path="/" Component={LandingPage} />
        </Routes>
      </BrowserRouter>
    </AppWrapper>
  );
};

export default App;
