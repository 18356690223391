import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Mail from "@mui/icons-material/Mail";
import Call from "@mui/icons-material/Call";
import emailjs from '@emailjs/browser';
import * as Yup from "yup";
import {
  FormContainer,
  InputContainer,
  SubmitButtonWrapper,
  SubmitButton,
  FieldContainer,
  IconContainer,
} from "./style";

const contactUsSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string().required("Email is required"),
  company: Yup.string().required("Company is required"),
  phone: Yup.string().required("Phone is required"),
  requirement: Yup.string().required("Requirement is required"),
});

const FormComponent = () => {
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("success");

  const handleClose = () => {
    setOpen(false);
  }
  return (
    <div>
      <Formik
        initialValues={{
          name: "",
          email: "",
          company: "",
          phone: "",
          address: "",
          requirement: "",
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          const templateParams = {
            name: values.name,
            company: values.company,
            email: values.email,
            phone: values.phone,
            address: values.address,
            requirement: values.requirement,
          }
          emailjs.send('service_sghwtv8', 'template_9uku9pn', templateParams, { publicKey: 'XnBkgdABKYXG3O1um'}).then(
            function (response) {
              setSeverity("success");
              setOpen(true);
              setSubmitting(false);
              resetForm({ 
                name: "",
                email: "",
                company: "",
                phone: "",
                address: "",
                requirement: "",
              });
            },
            function (err) {
              setSubmitting(false);
              setOpen(true);
              setSeverity("error");
            },
          );
        }}
        validationSchema={contactUsSchema}
      >
        {({ isSubmitting }) => (
          <Form>
            <FormContainer>
              <FieldContainer>
                <InputContainer>
                  <label htmlFor="name">Full Name</label>
                  <Field type="text" name="name" placeholder="" />
                  <ErrorMessage name="name" component="div" className="error" />
                </InputContainer>
                <InputContainer>
                  <label htmlFor="company">Company</label>
                  <Field type="text" name="company" placeholder="" />
                  <ErrorMessage name="company" component="div" className="error" />
                </InputContainer>
              </FieldContainer>
              <FieldContainer>
                <InputContainer>
                  <label htmlFor="email">Email</label>
                  <IconContainer>
                    <Mail />
                    <Field type="email" name="email" placeholder="" className="icon-added" />
                  </IconContainer>
                  <ErrorMessage name="email" component="div" className="error" />
                </InputContainer>
                <InputContainer>
                  <label htmlFor="phone">Phone</label>
                  <IconContainer>
                    <Call />
                    <Field type="number" name="phone" placeholder="" className="icon-added" />
                  </IconContainer>
                  <ErrorMessage name="phone" component="div" className="error" />
                </InputContainer>
              </FieldContainer>
              <InputContainer>
                <label htmlFor="address">Address</label>
                <Field type="text" name="address" placeholder="" />
                <ErrorMessage name="address" component="div" className="error" />
              </InputContainer>
              <InputContainer>
                <label htmlFor="requirement">Message</label>
                <Field
                  rows="5"
                  as="textarea"
                  type="text"
                  name="requirement"
                  placeholder="Type your message..."
                />
                <ErrorMessage name="requirement" component="div" className="error" />
              </InputContainer>
              <SubmitButtonWrapper>
                <SubmitButton type="submit" disabled={isSubmitting}>
                  Send Message
                </SubmitButton>
              </SubmitButtonWrapper>
            </FormContainer>
          </Form>
        )}
      </Formik>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        className="info"
      >
        <Alert
          onClose={handleClose}
          severity={severity}
          variant="filled"
        >
          {severity === "success" ? "Thank you for your response. Our team will get back to you soon!" : "Something went wrong. Please try again."}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default FormComponent;
