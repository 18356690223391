import React from "react";
import Header from "../../landing-page/header";
import AboutUsBanner from "./banner";
import Description from "./description";
import History from "./history";
import WorkLife from "./WorkLife";
import ImagesSection from "./image-container";
import CoreValue from "./core-values";
import NumbersSection from "./number";
import Footer from "../../landing-page/footer";

const AboutUs = () => {
  return (
    <div style={{ backgroundColor: "#1b1b1b"}}>
      <Header backgroundColor={"#1b1b1b"} />
      <AboutUsBanner />
      <NumbersSection />
      <div style={{ backgroundColor: "#fff"}}>
        <Description />
        <History />
        <WorkLife />
        {/* <ImagesSection /> */}
        <CoreValue />
      </div>
      <Footer />
    </div>
  );
};

export default AboutUs;
