import React, { useState } from "react";
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { FAQS_1, FAQS_2 } from "./constants";
import {
  FAQSectionWrapper,
  DescriptionTitle,
  ListWrapper,
  ListItem,
  Question,
  Answer,
} from "./style";

const FAQ = () => {
  const [isOpen, setIsOpen] = useState(-1);
  const handleAccordion = (index) => {
    if (isOpen === index) {
      setIsOpen(-1);
    } else {
      setIsOpen(index);
    }
  };
  return (
    <div style={{ backgroundColor: "#1b1b1b" }}>
      <FAQSectionWrapper>
        <DescriptionTitle>Frequently Asked Questions</DescriptionTitle>
        <ListWrapper>
          <div>
            {FAQS_1.map((item, index) => (
              <ListItem key={index} onClick={() => handleAccordion(index)}>
                <Question isOpen={isOpen === index}>
                  {item.ques}
                  <KeyboardDoubleArrowDownIcon isOpen />
                </Question>
                <Answer
                  isOpen={isOpen === index}
                  dangerouslySetInnerHTML={{ __html: item.ans }}
                />
              </ListItem>
            ))}
          </div>
          <div>
            {FAQS_2.map((item, index) => (
              <ListItem
                key={index + 4}
                onClick={() => handleAccordion(index + 4)}
              >
                <Question isOpen={isOpen === index + 4}>
                  {item.ques}
                  <KeyboardDoubleArrowDownIcon isOpen />
                </Question>
                <Answer
                  isOpen={isOpen === index + 4}
                  dangerouslySetInnerHTML={{ __html: item.ans }}
                />
              </ListItem>
            ))}
          </div>
        </ListWrapper>
      </FAQSectionWrapper>
    </div>
  );
};

export default FAQ;
