export const BLOGS_LIST = [
  {
    title:
      "Unveiling the Future: How 5G Will Revolutionize Mobile App Development",
    image: "5G_Blog.png",
    date: "24 January 2024",
    link: "/blogs/unveiling-the-future",
  },
  {
    title: "Mastering Modern Web Design: A Deep Dive into the Latest Trends",
    image: "Web_design_Blog.jpg",
    date: "10 January 2024",
    link: "/blogs/mastering-modern-web-design",
  },
  {
    title:
      "Unlocking the Power of IoT: Building Smart Solutions for the Future",
    image: "IOT_Blog.png",
    date: "10 January 2024",
    link: "/blogs/unlocking-the-power-of-iot",
  },
  {
    title:
      "The Future of Mobile App Development: Pioneering Innovation in a Connected World",
    image: "Mobile_Dev_Blog.png",
    date: "10 January 2024",
    link: "/blogs/the-future-of-mobile-app-development",
  },
];
