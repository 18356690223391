import styled from "styled-components";
import { SectionWrapper } from '../common-styles';

export const Container = styled(SectionWrapper)`
`;

export const ServicesSectionWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  @media only screen and (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
`;

export const ServiceDetailWrapper = styled.a`
  border: 1px solid white;
  background-color: white;
  display: flex;
  padding: 20px;
  border-radius: 12px;
  gap: 20px;
  border-radius: 10px;
  cursor: pointer;
  text-decoration: none !important;
  outline: 0 !important;
  @media only screen and (max-width: 500px) {
    flex-direction: column;
    align-items: center;
  }
`;


export const ImageWrapper = styled.img`
  width: 260px;
  @media only screen and (max-width: 1440px) {
    width: 220px;
  }
  @media only screen and (max-width: 1120px) {
    width: 150px;
  }
  @media only screen and (max-width: 500px) {
    width: 100%;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ServiceItemWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  div {
    background-color: #fc6600;
    border-radius: 50px;
    padding: 10px 20px;
    width: fit-content;
    color: #fff;
    font-size: 12px;
    font-weight: 400;
  }
`;

export const ServiceTitle = styled.div`
  font-size: 24px;
  font-weight: 600;
  color: #000;
  margin-bottom: 15px;
  line-height: 1.3;
  @media only screen and (max-width: 1900px) {
    font-size: 20px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 16px;
    margin-bottom: 5px;
  }
`;

export const ServiceDescription = styled.div`
  color: #000;
  margin-bottom: 20px;
  font-size: 16px;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.5;
  @media only screen and (max-width: 768px) {
    font-size: 12px;
    margin-bottom: 15px;
  }
`;
