import React from "react";
import { useParams } from "react-router";
import { TECHNOLOGIES_DETAILS } from "../../constants";
import {
  ApplicationInfoWrapper,
  LeftContainer,
  ImageContainer,
  RightContainer,
  ContentWrapper,
  Title,
  SubTitle,
} from "./style";

const ApplicationInfo = () => {
  const { id } = useParams();
  const data = TECHNOLOGIES_DETAILS[id] ?? null;
  return (
    <ApplicationInfoWrapper>
      <LeftContainer>
        <ImageContainer>
          <img src={data.applicationsInfoImage} alt="" width={"100%"} />
        </ImageContainer>
      </LeftContainer>
      <RightContainer>
        {data.applicationsInfo.map((item, index) => (
          <ContentWrapper key={index}>
            <Title>{item.title}</Title>
            <SubTitle>{item.subTitle}</SubTitle>
          </ContentWrapper>
        ))}
      </RightContainer>
    </ApplicationInfoWrapper>
  );
};

export default ApplicationInfo;
