import styled from "styled-components";
import { SectionWrapper, Description } from "../../common-style";

export const CoreValuesSectionWrapper = styled(SectionWrapper)``;

export const CoreValuesContainer = styled.div`
  display: grid;
  grid-template-columns: 40% 60%;
  @media only screen and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 15px;
  }
`;

export const Title = styled.div`
  display: flex;
  font-size: 24px;
  line-height: 1.2;
  font-weight: 500;
  color: #000;
  @media only screen and (max-width: 768px) {
    font-size: 14px;
    font-weight: 600;
  }
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const DetailsItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Number = styled.div`
  color: #fc6600;
  display: flex;
  gap: 8px;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.3;
  @media only screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

export const Divider = styled.div`
  display: inline-block;
  margin-right: 15px;
  width: 50px;
  height: 3px;
  font-weight: 500;
  background: linear-gradient(90deg, rgba(233, 116, 16, 1), rgba(233, 116, 16, 1));
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
`;

export const ItemTitle = styled.div`
  display: flex;
  font-size: 24px;
  line-height: 1.2;
  font-weight: 500;
  color: #fc6600;
  @media only screen and (max-width: 768px) {
    font-size: 14px;
    font-weight: 600;
  }
`;

export const Info = styled(Description)`
  color: #000;
`;
