import styled from "styled-components";
import { SectionWrapper, Description } from "../../common-style";

export const TitleSectionWrapper = styled(SectionWrapper)`
  color: #000;
  gap: 50px;
  padding-top: 175px;
  @media only screen and (max-width: 1440px) {
    padding-top: 100px;
  }
  @media only screen and (max-width: 768px) {
    padding-top: 12px;
    gap: 20px;
  }
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  background-color: #fff;
`;

export const DescriptionTitle = styled.div`
  display: flex;
  font-size: 24px;
  line-height: 1.2;
  font-weight: 500;
  color: #000;
  @media only screen and (max-width: 768px) {
    font-size: 14px;
    font-weight: 600;
  }
`;

export const DescriptionDescription = styled(Description)`
  color: #000;
`;
