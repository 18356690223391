import styled from "styled-components";
import { SectionWrapper } from '../common-styles';

export const BlogsSectionWrapper = styled(SectionWrapper)`
  padding-top: 100px;
`;

export const BlogsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  column-gap: 20px;
  row-gap: 20px;
  width: 100%;
  @media only screen and (max-width: 1024px) {
    grid-template-columns: repeat(2, auto);
  }
  @media only screen and (max-width: 600px) {
    grid-template-columns: repeat(1, auto);
  }
`;

export const BlogItem = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 20px;
  background-color: #fff;
`;

export const BlogContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px 0;
  gap: 20px;
  flex-grow: 1;
`;

export const BlogInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const BlogTitle = styled.div`
  color: #000;
  font-size: 28px;
  line-height: 1.3;
  font-weight: 700;
  @media only screen and (max-width: 1900px) {
    font-size: 21px;
  }
`;

export const BlogDate = styled.div`
  color: #000;
  line-height: 1.5;
  font-weight: 600;
  font-size: 20px;
  @media only screen and (max-width: 1900px) {
    font-size: 14px;
  }
`;

export const BlogLink = styled.a`
  display: flex;
  align-items: center;
  gap: 4px;
  color: #fc6600;
  margin: 0;
  font-size: 16px;
  outline: none;
  text-decoration: none;
  transition: all 0.35s ease;
  position: relative;
  width: fit-content;
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #fc6600;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

  &:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
`;

export const BlogImage = styled.img`
  width: 100%;
  height: 350px;
`;
