import styled from "styled-components";
import { SectionWrapper } from '../common-styles';

export const GradientWrapper = styled.div`
  background-image: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
`;

export const CertificationSectionWrapper = styled(SectionWrapper)``;

export const Cards = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
  width: 100%;
  padding: 2rem 0;
  @media screen and (min-width: 769px) {
    margin: 0;
    justify-content: flex-start;
    flex-direction: row;
    gap: 1rem;
  }
`;

export const Card = styled.div`
  position: relative;
  z-index: 1;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  height: 400px;
  width: ${({ isActive }) => (isActive ? "100%" : "10rem")};
  flex: ${({ isActive }) =>
      isActive ? `0 0 calc(100% - 44rem)` : `0 0 10rem`};
  @media screen and (max-width: 768px) {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .image-wrapper {
    height: ${({ isActive }) => (isActive ? "80%" : "100%")};
    width: 100%;
  }
  @media only screen and (max-width: 1024px) {
    .image-wrapper {
      height: ${({ isActive }) => (isActive ? "70%" : "100%")};
    }
  }
  @media only screen and (max-width: 768px) {
    .image-wrapper {
      flex-grow: 1;
    }
  }
  img {
    object-fit: cover;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    opacity: ${({ isActive }) => (isActive ? 1: 0.6 )};
    transition: all 0.5s ease-in-out;
    border-radius: 10px;
  }
  @media only screen and (max-width: 768px) {
    img {
      opacity: 1;
    }
  }
`;

export const TechDescription = styled.div`
  display: flex;
  opacity: ${({ selectedCard }) => (selectedCard ? 1 : 0)};
  transition: opacity 1.25s ease-in-out;
  color: ${({ selectedCard }) => (selectedCard ? "#000" : "transparent")};
  font-size: 18px;
  line-height: 25px;
  margin-top: 8px;
  @media screen and (max-width: 768px) {
    color: #000;
    opacity: 1;
    transition: none;
    font-size: 12px;
    line-height: 1.5;
  }
`;
