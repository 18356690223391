import React from "react";
import { useParams } from "react-router";
import Header from "../../landing-page/header";
import ServicesItemDetail from "./details";
import Footer from "../../landing-page/footer";
import { SERVICES_DETAILS } from "./constants";

const ServiceDetails = () => {
  const { id } = useParams();
  const data = SERVICES_DETAILS[id] ?? null;
  if (!data) {
    return (
      <>
        <Header />
        <Footer />
      </>
    );
  }
  return (
    <>
      <Header />
      <ServicesItemDetail data={data} />
      <Footer />
    </>
  );
};

export default ServiceDetails;
