import React from "react";
import { TECHNOLOGIES_DATA } from "./constants";
import { TitleWrapper,InfoWrapper, Title, Description } from '../common-styles';
import {
  TechnologiesSectionWrapper,
  TechContainer,
  TechContainerItem,
  TechContainerLeftItem,
  TechContainerLeftItemTitle,
  TechContainerLeftItemDescription,
  TechContainerLeftInfoDescription,
  TechContainerRightItem,
  TechTechnologyImage,
} from "./style";

const TechnologiesComponent = () => {
  return (
    <div style={{ backgroundColor: "#1b1b1b" }}>
      <TechnologiesSectionWrapper>
      <TitleWrapper>
        <InfoWrapper>
          <Title>Our Technologies</Title>
            <Description>
              "Explore Cutting-Edge Technologies with Techabes "
            </Description>
          </InfoWrapper>
        </TitleWrapper>  
        <TechContainer>
          {TECHNOLOGIES_DATA.map((tech, index) => (
            <TechContainerItem key={index}>
              <TechContainerLeftItem>
                <TechContainerLeftItemTitle>
                  {tech.tech}
                </TechContainerLeftItemTitle>
                <TechContainerLeftInfoDescription>
                  {tech.info}
                </TechContainerLeftInfoDescription>
                <TechContainerLeftItemDescription>
                  {tech.description.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </TechContainerLeftItemDescription>
              </TechContainerLeftItem>
              <TechContainerRightItem>
                <TechTechnologyImage src={tech.imageUrl} alt="" />
              </TechContainerRightItem>
            </TechContainerItem>
          ))}
        </TechContainer>
      </TechnologiesSectionWrapper>
    </div>
  );
};

export default TechnologiesComponent;
