import React from "react";
import { SERVICES_DETAILS } from "./constants";
import { TitleWrapper,InfoWrapper, Title, Description } from '../common-styles';
import {
  Container,
  ServicesSectionWrapper,
  ServiceDetailWrapper,
  ServiceTitle,
  ServiceDescription,
  ImageWrapper,
  ContentWrapper,
  ServiceItemWrapper,
} from "./style";

const Services = () => {
  return (
    <Container>
      <TitleWrapper>
        <InfoWrapper>
          <Title>Our Services</Title>
          <Description>
            "Elevate your business with our comprehensive suite of IT services"
          </Description>
        </InfoWrapper>
      </TitleWrapper>
      <ServicesSectionWrapper>
        {SERVICES_DETAILS.map((service) => (
          <ServiceDetailWrapper href={service.link} key={service.title}>
            <ImageWrapper src={service.image} alt="" />
            <ContentWrapper>
              <ServiceTitle>{service.title}</ServiceTitle>
              <ServiceDescription>{service.description}</ServiceDescription>
              <ServiceItemWrapper>
                {service.items.map((item) => (
                  <div key={item.title}>{item.title}</div>
                ))}
              </ServiceItemWrapper>
            </ContentWrapper>
          </ServiceDetailWrapper>
        ))}
      </ServicesSectionWrapper>
    </Container>
  );
};

export default Services;
