import styled from "styled-components";
import { SectionWrapper } from "../../common-style";

export const ImageWrapper = styled(SectionWrapper)`
  padding-top: 100px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
  @media only screen and (max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const ImageDataContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const ImageDataContainerTitle = styled.div`
  font-size: 60px;
  font-weight: 600;
  line-height: 1.3;
  color: #fff;
  @media only screen and (max-width: 1440px) {
    font-size: 40px;
    font-weight: 700;
  }
  @media only screen and (max-width: 768px) {
    font-size: 32px;
    font-weight: 700;
  }
`;
