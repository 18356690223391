import styled from "styled-components";
import { SectionWrapper, Title, Description } from '../../../common-style';

export const DescriptionWrapper = styled(SectionWrapper)``;

export const DescriptionTitle = styled(Title)`
`;

export const DescriptionSubTitle = styled(Description)`
  @media only screen and (max-width: 1024px) {
    br {
      display: none;
    }
  }
`;

export const ServicesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 4rem;
  border-radius: 5px;
  overflow: hidden;
  @media only screen and (max-width: 900px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const ServicesLeftContainer = styled.div`
  display: flex;
  align-items: center;
  background: linear-gradient(90deg, rgba(28, 9, 9, 1), rgba(233, 116, 16, 1));
`;

export const ServicesLeftInnerContainer = styled.div`
  padding: 50px 100px;
  color: #fff;
  display: flex;
  align-items: center;
  @media only screen and (max-width: 1150px) {
    padding: 50px;
  }
  @media only screen and (max-width: 900px) {
    width: 100%;
    justify-content: center;
  }
  .tab-content {
    display: none;
  }
  .current {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  .content {
    display: none;
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  border-radius: 10px;
  padding: 25px;
  background-color: #fff;
  width: 150px;
  height: 150px;
  box-sizing: border-box;
  img {
    height: 100px;
    width: 100px;
  }
`;

export const AccordianHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap:30px;
  align-items: center;
  justify-content: center;
  .serv_card_head {
    display: flex;
    align-items: center;
    font-size: 24px;
    line-height: 1.2;
    font-weight: 500;
    color: #fff;
    @media only screen and (max-width: 900px) {
      font-size: 16px;
    }
  }
`;

export const AccordianBody = styled.div`
  display: block;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  @media only screen and (max-width: 900px) {
    font-size: 12px;
  }
`;

export const ServicesRightContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px 40px;
  background-color: #fff;
  @media only screen and (max-width: 900px) {
    padding: 20px;
  }
  ul {
    overflow-y: scroll;
    height: 420px;
    padding: 24px 0;
    margin: 0;
    list-style-type: none;
    box-sizing: border-box;
    .tab-link {
      color: #000;
      margin: 0;
      list-style-type: none;
      font-size: 24px;
      font-weight: 400;
      padding: 20px 0;
      line-height: 24px;
      span {
        display: flex;
        align-items: center;
      }
      cursor: pointer;
      @media only screen and (max-width: 900px) {
        font-size: 14px;
      }
      &:not(:last-child) {
        border-bottom: 1px solid #000;
      }
      .scroll_list_num {
        display: inline-block;
        margin-right: 15px;
        width: 25px;
        height: 3px;
        font-weight: 500;
        background: linear-gradient(90deg, rgba(233, 116, 16, 1), rgba(233, 116, 16, 1));
        @media only screen and (max-width: 912px) {
          left: -45px;
        }
      }
    }
    .current {
      color: #fc6600;
    }
  }
`;
