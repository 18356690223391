import styled from "styled-components";

/** Detailed Page Form */

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  @media only screen and (max-width: 500px) {
    gap: 20px;
  }
`;

export const FieldContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  @media only screen and (max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const InputContainer = styled.div`
  display: flex;
  /* width: 100%; */
  flex-direction: column;
  margin-bottom: 20px;
  @media only screen and (max-width: 500px) {
    margin-bottom: 0;
  }
  .error{
    color: red;
    font-size: 12px;
  }
  label {
    margin-bottom: 5px;
    font-size: 16px;
    color: #000;
    @media only screen and (max-width: 500px) {
      font-size: 12px;
    }
  }
  input {
    width: 100%;
    height: 30px;
    border-radius: 4px;
    padding: 4px;
    border: 0;
    background-color: rgba(245, 245, 245, 1);
    &:focus {
      outline: none;
    }
  }
  textarea {
    border-radius: 4px;
    border: 0;
    padding: 8px;
    background-color: rgba(245, 245, 245, 1);
    &:focus {
      outline: none;
    }
  }
  select {
    border: 0;
    background-color: rgba(245, 245, 245, 1);
  }
  .icon-added {
    width: 100%;
    border-radius: 0;
    border: 0;
    padding: 0;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
  border-radius: 4px;
  border: 0;
  background-color: rgba(245, 245, 245, 1);
  padding: 4px;
  gap: 4px;
  color: black;
`;

export const SubmitButtonWrapper = styled.div`
  display: flex;
`;

export const SubmitButton = styled.button`
  display: flex;
  align-items: center;
  padding: 12px 24px;
  background-color: #fc6600;
  color: #fff;
  border: 0;
  border-radius: 5px;
  cursor: pointer;
  width: fit-content;
`;