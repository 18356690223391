import styled from "styled-components";
import { SectionWrapper, Title, Description } from '../../common-style';

export const BlogItemDetailContainer = styled(SectionWrapper)`
  padding-top: 100px;
`;

export const BlogsTitle = styled(Title)``;

export const ImageTitle = styled.div`
  margin-top: 20px;
`;

export const BlogsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 40px;
  width: 100%;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const LeftWrapper = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;
  ul {
    color: #fff;
    padding-left: 0;
    list-style-type: none;
    li {
      border-top: 1px solid rgba(255, 255, 255, 1);
      padding: 20px 0;
      display: flex;
      align-items: center;
      gap: 15px;
      
    }
  }
  .scroll_list_text {
        cursor: pointer;
        color: #fff;
        font-size: 24px;
        line-height: 24px;
        @media only screen and (max-width: 768px) {
          font-size: 14px;
        }
      }
  .scroll_list_num {
        display: inline-block;
        margin-right: 15px;
        width: 25px;
        height: 3px;
        font-weight: 500;
        background: linear-gradient(90deg, rgba(233, 116, 16, 1), rgba(233, 116, 16, 1));
        @media only screen and (max-width: 912px) {
          left: -45px;
        }
      }
    @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const RightWrapper = styled.div`
  width: 55%;
  color: #fff;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const RightWrapperItem = styled.div`
  font-size: 20px;
  line-height: 1.5;
  padding-bottom: 15px;
  span {
    color: #fc6600;
    font-weight: 600;
    margin-right: 5px;
  }
  @media only screen and (max-width: 1900px) {
    font-size: 16px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
`;

export const RightItemTitle = styled(Title)`
  color: #fc6600;
  padding: 24px 0;
`;
