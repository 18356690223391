import styled from "styled-components";

export const HeaderContentWrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  ul {
    display: flex;
    margin: 0;
    padding: 0%;
    li {
      list-style-type: none;
      margin: ${({ shiftMargin }) => shiftMargin ? '15px 0' : '0' };
      cursor: pointer;
      .header-item {
        color: #fff;
        position: relative;
        font-size: 18px;
        margin: 20px 25px;
        display: block;
        z-index: 9;
        font-weight: 600;
        &::after {
          content: "";
          position: absolute;
          width: 100%;
          transform: scaleX(0);
          height: 2px;
          bottom: 0;
          left: 0;
          background-color: #fff;
          transform-origin: bottom right;
          transition: transform 0.25s ease-out;
        }

        &:hover::after {
          transform: scaleX(1);
          transform-origin: bottom left;
        }
      }
     .contact-us-button {
        text-decoration: none;
        list-style-type: none;
        color: #fff;
        border: 1px solid #fc6600;
        background-color: #fc6600;
        font-weight: 600;
        padding: 12px 35px;
        border-radius: 30px;
        margin: 15px 0;
        transition: 1s;
        font-size: 18px;
        &:hover {
          color: #fc6600;
          box-shadow: inset 300px 0 0 0 #fff;
        }
      }
      &:hover .selected-item {
        visibility: visible;
        opacity: 1;
        height: 300px;
        width: 100%;
      }
    }
  }
  @media only screen and (max-width: 1024px) {
    display: none;
  }
`;

export const HeaderItemMasterWrapper = styled.div`
  background-color: #fff;
`;

export const HeaderItemWrapper = styled.div`
  color: black;
  background-color: #fff;
  position: fixed;
  right: 0;
  z-index: 100;
  box-sizing: border-box;
  visibility: hidden;
  opacity: 0;
  padding: 30px;
  display: flex;
  gap: 20px;
`;

export const HeaderItem = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 40px;
`;

export const HeaderTitle = styled.a`
  font-size: 18px;
  margin-bottom: 10px;
  text-decoration: none;
  list-style-type: none;
  color: #fc6600;
`;

export const HeaderSubTitle = styled.div`
  font-size: 14px;
  margin-top: 8px;
`;