import React, { useState } from "react";
import {
  HistorySectionWrapper,
  HeadingTitle,
  HistoryTitle,
  HistoryDetails,
  HistoryLeftContainer,
  HistoryRightContainer,
  HistoryDescription,
  HistoryTabWrapper,
  HistoryTabItem,
  TabLabel,
  TabIcon,
} from "./style";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div sx={{ p: 3 }}>
          <div>{children}</div>
        </div>
      )}
    </div>
  );
}

const history_data = [
  {
    index: 0,
    title: "2021: The Beginning",
    titleBanner: "2021",
    description:
      "Techabes was founded with a vision to revolutionize digital development. We started small, focusing on creating personalized tech solutions for emerging startups and niche markets.",
    img_src:
      "https://d29z5i6uc22g11.cloudfront.net/imageour-history-1.svg",
  },
  {
    index: 1,
    title: "2022: Expansion",
    titleBanner: "2022",
    description:
      "By 2022, we gained momentum, securing projects with businesses across various industries. Our expertise in custom solutions helped us expand our services into new verticals such as healthcare and e-commerce.",
    img_src:
      "https://d29z5i6uc22g11.cloudfront.net/imageour-history-1.svg",
  },
  {
    index: 2,
    title: "2023: Innovation Year",
    titleBanner: "2023",
    description:
      "In 2023, Techabes became known for its innovative approach to solving complex problems. We worked closely with clients in the daycare and real estate sectors, delivering advanced, tailor-made platforms that streamlined their operations.",
    img_src:
      "https://d29z5i6uc22g11.cloudfront.net/imageour-history-1.svg",
  },
  {
    index: 3,
    title: "2024: Tech Pioneers",
    titleBanner: "2024",
    description:
      "Now in 2024, Techabes stands as a trusted name in custom app and web development. We've grown from a boutique development firm to a major player, pushing the boundaries of what's possible in the digital world.",
    img_src:
      "https://d29z5i6uc22g11.cloudfront.net/imageour-history-1.svg",
  },
];

const History = () => {
  const [value, setValue] = useState(0);

  const handleChange = (newValue) => {
    setValue(newValue);
  };
  return (
    <div style={{ backgroundColor: "#fff" }}>
      <HistorySectionWrapper>
        <HeadingTitle>Our History</HeadingTitle>
        {history_data.map((item) => (
          <CustomTabPanel value={value} index={item.index}>
            <HistoryDetails>
              <HistoryLeftContainer>
                <HistoryTitle>{item.title}</HistoryTitle>
                <HistoryDescription>{item.description}</HistoryDescription>
              </HistoryLeftContainer>
              <HistoryRightContainer>
                <img src={item.img_src} alt={""} width={'100%'} />
              </HistoryRightContainer>
            </HistoryDetails>
          </CustomTabPanel>
        ))}
        <HistoryTabWrapper>
          {history_data.map((item, index) => (
            <HistoryTabItem
              onClick={() => handleChange(item.index)}
              isSelected={item.index === value}
              index={index}
            >
              <TabLabel isSelected={item.index === value}>
                {item.index === value ? <TabIcon src="https://d29z5i6uc22g11.cloudfront.net/tech-abes-logo-mini.svg" /> : null}

                <span>{item.titleBanner}</span>
              </TabLabel>
            </HistoryTabItem>
          ))}
        </HistoryTabWrapper>
      </HistorySectionWrapper>
    </div>
  );
};

export default History;
